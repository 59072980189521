import { StateCreator } from 'zustand';

import { IOnboardingStore } from './types';

export const onboardingSlice: StateCreator<IOnboardingStore, [], [], IOnboardingStore> = (set) => ({
  activeOriginOnboardingScreen: null,
  onboardingAnswer: null,
  isTutorialBlocked: false,
  changeIsTutorialBlocked: (isTutorialBlocked) => set({ isTutorialBlocked }),
  changeActiveOriginOnboardingScreen: (screen) => set({ activeOriginOnboardingScreen: screen }),
  changeOnboardingAnswer: (answer) => set({ onboardingAnswer: answer }),
});
