import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import { RoutePaths, SessionModalStatus } from 'common-types';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { invertedButtonColor, resetButtonStyles } from 'styles';

import { modalStyles } from '../../../../pages/stylist/styles';
import {
  sessionStatusButtonMap,
  sessionStatusImageMap,
  sessionStatusSecondaryButtonMap,
  sessionStatusSubtitleMap,
  sessionStatusTitleMap,
} from '../../../../pages/stylist/utils/data';

interface SessionStatusModalProps {
  type: SessionModalStatus;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirmationButtonClick: () => void;
  handleSecondaryButtonClick?: () => void;
}

export const SessionStatusModal = memo<SessionStatusModalProps>(
  ({ type, isOpen, handleClose, handleConfirmationButtonClick, handleSecondaryButtonClick }) => {
    const location = useLocation();

    const isBookingPage = location.pathname === RoutePaths.BOOKING;

    return (
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={modalStyles}>
          {type !== SessionModalStatus.TIME_OUT && (
            <Box position="absolute" top={0} right={0} p={2}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Box display="flex" justifyContent="center" height={108} mb={{ xs: 6, lg: 8 }}>
            {sessionStatusImageMap[type]}
          </Box>
          <Box maxWidth={550} mx="auto" mb={{ xs: 23.5, lg: 13 }}>
            <Typography variant="h2" component="p" fontSize={29} textAlign="center" mb={4}>
              {sessionStatusTitleMap[type]}
            </Typography>
            <Typography variant="body1" fontSize={17} textAlign="center" color="custom.textGrey">
              {sessionStatusSubtitleMap[type]}
            </Typography>
          </Box>
          <Stack gap={6} maxWidth={406} mx="auto">
            {!isBookingPage && (
              <Button onClick={handleConfirmationButtonClick} sx={[invertedButtonColor, { py: 4 }]}>
                {sessionStatusButtonMap[type]}
              </Button>
            )}
            {sessionStatusSecondaryButtonMap[type] && (
              <Box
                component="button"
                onClick={handleSecondaryButtonClick}
                sx={[
                  resetButtonStyles,
                  {
                    fontSize: 17,
                    letterSpacing: 0.5,
                    color: 'primary.main',
                    textAlign: 'center',
                  },
                ]}
              >
                {sessionStatusSecondaryButtonMap[type]}
              </Box>
            )}
          </Stack>
        </Box>
      </Modal>
    );
  },
);
