import GuideImage from '../assets/guides-modal.jpg';
import StylistImg from '../assets/stylist-modal.jpg';
import StylePlanImg from '../assets/style-plan-modal.jpg';

// types
import { IRouteMap } from './types';
import { RoutePaths } from 'common-types';

export const routeMap: IRouteMap = {
  [RoutePaths.GUIDES]: {
    src: GuideImage,
    alt: 'Guides',
    title:
      'Your Personal Style Book and other Fashion Guides are full of personalized advice to help you look and feel amazing.',
  },
  [RoutePaths.STYLIST]: {
    src: StylistImg,
    alt: 'Stylist',
    title:
      'Welcome your personal stylist! Get 24/7 advice from trusted fashion experts to help discover the real You with personality-based style tips.',
  },
  [RoutePaths.STYLE_PLAN]: {
    src: StylePlanImg,
    alt: 'Style Plan',
    title:
      'Gain a wealth of applied knowledge you can use to improve your style and express your unique You by completing your Style Plan.',
  },
};
