import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { HttpCodeSessionException, ICreateSessionData, QueryKey, RoutePaths } from 'common-types';
import { useGetLastUserSession } from 'hooks/use-get-last-user-session';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useAnalytics } from 'services/analytics';
import { useStore } from 'store';
import { shallow } from 'zustand/shallow';

import chicApi from '../../services/chic-api';

export const useSetNewMeet = (): UseMutationResult<unknown, unknown, ICreateSessionData, unknown> => {
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const { lastUserSession } = useGetLastUserSession();

  const { setIsConfirmModalOpen } = useStore(
    (state) => ({
      setIsConfirmModalOpen: state.setIsConfirmModalOpen,
    }),
    shallow,
  );

  return useMutation([QueryKey.SET_NEW_MEET], (data) => chicApi.addSession(data), {
    onError: (error: unknown) => {
      trackEvent('booking_screen_api_error', {
        error:
          error instanceof AxiosError && error?.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT
            ? 'Session limit'
            : 'Request failed',
        placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
        type: 'book',
      });

      if (error instanceof AxiosError && error?.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT) return;

      toast.error('Something went wrong, please try again', {
        icon: ErrorOutlineIcon,
      });
    },
    onSuccess: () => {
      if (!lastUserSession) {
        setIsConfirmModalOpen(true);
      }
    },
  });
};
