import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from 'providers/theme/theme';
import { memo } from 'react';
import { useStore } from 'store';
import { invertedButtonColor } from 'styles';
import { shallow } from 'zustand/shallow';

import CoverImg from '../../assets/review-bg-1.png';
import CoverMobileImg from '../../assets/review-bg-2.png';
import { ReactComponent as LikeIcon } from '../../assets/review-icon.svg';

export const FeedbackSuccess = memo(() => {
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('lg'));
  const { updateReviewModal } = useStore(
    (state) => ({
      updateReviewModal: state.updateReviewModal,
    }),
    shallow,
  );

  const handleClick = (): void => {
    updateReviewModal();
  };

  return (
    <>
      <Box mb={{ xs: '-100px', lg: '-80px' }}>
        <Box
          component="img"
          src={isMediumAndDown ? CoverMobileImg : CoverImg}
          sx={{
            width: 1,
            height: 1,
            objectFit: 'contain',
            objectPosition: 'top center',
          }}
        />
      </Box>
      <Stack px={4} maxWidth={467} mx="auto" sx={{ pb: 15, textAlign: 'center' }}>
        <Box mb={{ xs: 6, lg: 8 }}>
          <LikeIcon />
        </Box>
        <Typography variant="body1" fontSize={24} fontWeight={700} mb={2}>
          Feedback was shared!
        </Typography>
        <Typography variant="body1" fontSize={16} mb={8} color="custom.textGrey">
          Thank you for your feedback. Our team will consider your answer to become better
        </Typography>
        <Button onClick={handleClick} sx={[invertedButtonColor, { py: 4 }]}>
          ok
        </Button>
      </Stack>
    </>
  );
});
