import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { HttpCodeSessionException, IEditSessionData, QueryKey, RoutePaths } from 'common-types';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useAnalytics } from 'services/analytics';
import chicApi from 'services/chic-api';

interface IEditMeet {
  meetId: string;
  data: IEditSessionData;
}

export const useEditMeet = (): UseMutationResult<unknown, unknown, IEditMeet, unknown> => {
  const { trackEvent } = useAnalytics();
  const location = useLocation();

  return useMutation([QueryKey.EDIT_MEET], ({ meetId, data }: IEditMeet) => chicApi.editSession(meetId, data), {
    onError: (error: unknown) => {
      trackEvent('booking_screen_api_error', {
        error: 'Request failed',
        placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
        type: 'edit',
      });

      if (
        error instanceof AxiosError &&
        (error.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT ||
          error.response?.data?.code === HttpCodeSessionException.SESSION_IS_STARTED)
      )
        return;

      toast.error('Something went wrong, please try again', {
        icon: ErrorOutlineIcon,
      });
    },
  });
};
