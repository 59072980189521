import { Identify, identify } from '@amplitude/analytics-browser';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { EFeatureName } from 'common-types/features';
import { useGetUserData } from 'hooks/use-get-user-data';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TSubscriptionFreezeABTest } from 'types/ab-test';
import { isProduction } from 'utils';

const useSendUserProperties = (): void => {
  const { funnelId, userId, userEmail, userDomain, userDevice, profileName } = useGetUserData();

  const identifyEvent = useMemo(() => new Identify(), []);

  const [searchParams] = useSearchParams();

  const source = searchParams.get('source');
  const growthContext = useGrowthBook();
  const isGrowthBookReady = growthContext.ready;
  const subscriptionFreezeABTest = useMemo(() => {
    if (!isGrowthBookReady) return null;
    return growthContext.getFeatureValue<TSubscriptionFreezeABTest | null>(
      EFeatureName.FEATURE_SUBSCRIPTION_FREEZE,
      null,
    );
  }, [growthContext, isGrowthBookReady]);

  useEffect(() => {
    identifyEvent.set('user_environment', isProduction() ? 'prod' : 'dev');
    identifyEvent.set('user_product', 'Lumi');

    if (funnelId) {
      identifyEvent.set('funnel_id', funnelId);
    }

    if (userId) {
      identifyEvent.set('user_id', userId);
    }

    if (userEmail) {
      identifyEvent.set('user_email', userEmail);
    }

    if (userDomain) {
      identifyEvent.set('user_domain', userDomain);
    }

    if (userDevice) {
      identifyEvent.set('user_platform', userDevice === 'iOS' ? 'ios' : 'web');
    }

    if (source) {
      identifyEvent.set('user_source', source);
    }

    if (profileName) {
      identifyEvent.set('user_profile', profileName);
    }

    if (subscriptionFreezeABTest) {
      identifyEvent.set('subscription_freeze_feature_enabled', {
        timestamp: subscriptionFreezeABTest.timestamp,
        value: subscriptionFreezeABTest.value,
        period: subscriptionFreezeABTest.period,
      });
    }

    identify(identifyEvent);
  }, [
    funnelId,
    identifyEvent,
    subscriptionFreezeABTest,
    profileName,
    source,
    userDevice,
    userDomain,
    userEmail,
    userId,
  ]);
};

export default useSendUserProperties;
