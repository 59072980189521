import { Box, Stack, SxProps, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import ProfileImage from 'assets/images/profile-photo.png';
import { RoutePaths } from 'common-types';
import { useGetUserData } from 'hooks/use-get-user-data';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { shallow } from 'zustand/shallow';

type THeaderBookingProps = {
  styleSx?: SxProps;
};

export const HeaderBooking = memo<THeaderBookingProps>(({ styleSx }) => {
  const { userPhoto, userName } = useGetUserData();

  const { changeIsTutorialBlocked } = useStore(
    (state) => ({
      changeIsTutorialBlocked: state.changeIsTutorialBlocked,
    }),
    shallow,
  );

  const handleClick = (): void => {
    changeIsTutorialBlocked(false);
  };

  return (
    <Box component="header" position="fixed" top={0} left={0} width={1} bgcolor="transparent" zIndex={1}>
      <Box maxWidth={1128} mx="auto" px={4} py={{ xs: 2, lg: 3 }} sx={styleSx}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box
            component={Link}
            to={RoutePaths.HOME}
            display="flex"
            onClick={handleClick}
            sx={{ transition: 'opacity 0.2s ease', '&:hover': { opacity: 0.6 } }}
          >
            <Logo />
          </Box>
          <Stack direction="row" alignItems="center" gap={4}>
            <Typography variant="body1" fontSize={16}>
              Hi, {userName || 'Gorgeous'}!
            </Typography>
            <Box
              component="img"
              src={userPhoto || ProfileImage}
              width={{ xs: 32, lg: 40 }}
              height={{ xs: 32, lg: 40 }}
              borderRadius="50%"
              draggable={false}
              sx={{ objectFit: 'cover', overflow: 'hidden', transition: 'opacity 0.2s ease-in-out' }}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
});
