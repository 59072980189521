import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, MenuItem, Popper, TextField, Typography } from '@mui/material';
import { BookingFormField, ReminderValue } from 'common-types';
import { modifiers, reminderValueMap } from 'components/booking/utils/data';
import { memo, MouseEvent, useRef, useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

import { iconHolderStyles, menuItemStyles, popperStyles } from '../add-session-form/styles';

interface ReminderDropdownProps {
  field: ControllerRenderProps<FieldValues, BookingFormField.REMINDER>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const ReminderDropdown = memo<ReminderDropdownProps>(({ field, isOpen, onOpen, onClose }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleHover = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleLeave = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box position="relative">
      <Box
        position="absolute"
        top={16}
        right={56}
        zIndex={1}
        color="custom.greyDark"
        ref={anchorRef}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <HelpOutlineOutlinedIcon />
        <Popper
          placement="bottom"
          disablePortal={false}
          open={Boolean(anchorEl)}
          anchorEl={anchorRef.current}
          sx={popperStyles}
          modifiers={modifiers}
        >
          <Typography variant="body2" textAlign="center" color="common.black">
            Select the messenger to get notification about session
          </Typography>
        </Popper>
      </Box>
      <Box position="relative">
        <Box sx={iconHolderStyles}>{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Box>
        <TextField
          {...field}
          fullWidth
          select
          SelectProps={{
            IconComponent: (): null => null,
            onOpen,
            onClose,
            displayEmpty: true,
          }}
        >
          <MenuItem disabled value="" sx={{ display: 'none' }}>
            <Typography variant="body1" fontSize={16} color="custom.textGrey">
              Reminder
            </Typography>
          </MenuItem>
          {Object.values(ReminderValue).map((value) => (
            <MenuItem key={value} value={value} sx={menuItemStyles}>
              <Typography variant="body1" fontSize={16}>
                {reminderValueMap[value]}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
});
