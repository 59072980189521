import { UserSource } from 'common-types';
import { tutorialSteps } from 'data/tutorial';
import { SUBSCRIPTION_VARIANT } from 'pages/renew-subscription-landing/components/payment/utils/types';
import { StateCreator } from 'zustand';

import { IUserStore } from './types';

export const userSlice: StateCreator<IUserStore, [], [], IUserStore> = (set, get) => ({
  email: '',
  isSessionInitialized: false,
  guideType: null,
  isDrawerDialogOpen: false,
  photo: '',
  isModalOpened: false,
  isResetPasswordModalOpened: false,
  userStatus: '',
  partView: null,
  userGoal: '',
  ultimateWhy: '',
  isOnboardingStepsOpen: false,
  currentUserOnboardingStep: 0,
  currentOnboardingStepRef: null,
  moodRef: null,
  yourWayRef: null,
  specialOfferRef: null,
  notesRef: null,
  recentRef: null,
  profileRef: null,
  paymentRef: null,
  isPasswordChangeModalOpened: false,
  isUserWithVat: false,
  activePaymentCard: SUBSCRIPTION_VARIANT.MONTHLY_PREMIUM,
  subscriptionUserSource: UserSource.WEB,
  restoredStyleSession: null,
  isOpenPaymentModal: false,
  unsubscribeSource: null,
  setUnsubscribeSource: (unsubscribeSource) => set({ unsubscribeSource }),
  setIsOpenPaymentModal: (isOpenPaymentModal: boolean) => set({ isOpenPaymentModal }),
  setRestoredStyleSession: (restoredStyleSession) => set({ restoredStyleSession }),
  setSubscriptionUserSource: (subscriptionUserSource) => set({ subscriptionUserSource }),
  setActivePaymentCard: (activePaymentCard) => set({ activePaymentCard }),
  setEmail: (email) => set({ email }),
  setIsSessionInitialized: (isSessionInitialized) => set({ isSessionInitialized }),
  setGuideType: (guideType) => set({ guideType }),
  setIsDrawerDialogOpen: (isDrawerDialogOpen) => set({ isDrawerDialogOpen }),
  setPhoto: (photo) => set({ photo }),
  setIsModalOpened: (isModalOpened) => set({ isModalOpened }),
  setIsResetPasswordModalOpened: (isResetPasswordModalOpened) => set({ isResetPasswordModalOpened }),
  setUserStatus: (userStatus) => set({ userStatus }),
  setPartView: (partView) => set({ partView }),
  setUserGoal: (userGoal) => set({ userGoal }),
  setUltimateWhy: (ultimateWhy) => set({ ultimateWhy }),
  setIsOnboardingStepsOpen: (isOnboardingStepsOpen) => set({ isOnboardingStepsOpen }),
  setCurrentUserOnboardingStep: (userOnboardingStep) => set({ currentUserOnboardingStep: userOnboardingStep }),
  setCurrentOnboardingStepRef: (currentOnboardingStep) => set({ currentOnboardingStepRef: currentOnboardingStep }),
  setMoodRef: (moodRef) => set({ moodRef }),
  setYourWayRef: (yourWayRef) => set({ yourWayRef }),
  setSpecialOfferRef: (specialOfferRef) => set({ specialOfferRef }),
  setNotesRef: (notesRef) => set({ notesRef }),
  setRecentRef: (recentRef) => set({ recentRef }),
  setProfileRef: (profileRef) => set({ profileRef }),
  setPaymentRef: (paymentRef) => set({ paymentRef }),
  setIsPasswordChangeModalOpened: (isPasswordChangeModalOpened) => set({ isPasswordChangeModalOpened }),
  setIsUserWithVat: (isUserWithVat) => set({ isUserWithVat }),
  nextStep: (): void => {
    const getCurrentOnboardingStep = (): HTMLDivElement => {
      switch (get().currentUserOnboardingStep + 1) {
        case 0:
          return get().moodRef;
        // case 1:
        //   return get().specialOfferRef;
        case 1:
          return get().yourWayRef;
        case 2:
          return get().notesRef;
        case 3:
          return get().recentRef;
        case 4:
          return get().profileRef;
        default:
          return null;
      }
    };
    set((state) => {
      getCurrentOnboardingStep()?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (state.currentUserOnboardingStep < tutorialSteps.length - 1) {
        return {
          currentUserOnboardingStep: state.currentUserOnboardingStep + 1,
          currentOnboardingStepRef: getCurrentOnboardingStep(),
        };
      }

      return {
        currentUserOnboardingStep: 0,
        currentOnboardingStepRef: null,
        isUserOnboardingDone: true,
        isUserOnboardingStarted: false,
        isOnboardingStepsOpen: false,
      };
    });
  },
});
