export const invertedButtonColor = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  // '&:active, &:focus-visible, &:focus:not(:focus-visible), &:not(:focus-visible)': {
  //   bgcolor: 'primary.main',
  //   color: 'common.white',
  // },
  '&:hover': {
    bgcolor: 'custom.buttonColor',
    color: 'primary.main',
    '@media (hover: none)': {
      bgcolor: 'primary.main',
      color: 'common.white',
    },
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
};

export const resetButtonStyles = {
  border: 'none',
  textAlign: 'left',
  overflow: 'visible',
  background: 'transparent',
  cursor: 'pointer',
  color: 'inherit',
};

export const resetMuiButtonStyles = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
};
