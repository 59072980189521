import { track } from '@amplitude/analytics-browser';
import { useCallback } from 'react';
import { useLocalStorageStore } from 'store';
import { shallow } from 'zustand/shallow';

export const useAnalytics = (): {
  trackEvent: (event: string, extraFields?: Record<string, string | number | boolean>, screenName?: string) => void;
} => {
  // const location = useLocation();
  const { userId } = useLocalStorageStore(
    (state) => ({
      userId: state.userId,
    }),
    shallow,
  );

  const trackEvent = useCallback(
    (event: string, extraFields = {}) => {
      // const screen = screenName || location.pathname.slice(1).replace('-', '_') || 'home';

      const options = {
        ...extraFields,
        user_id: userId,
      };

      track(event, options);
    },
    [userId],
  );
  return { trackEvent };
};
