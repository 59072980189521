export enum Mood {
  COZY = 'Cozy',
  ACTIVE = 'Active',
  ROMANTIC = 'Romantic',
  DARING = 'Daring',
  CONFIDENT = 'Confident',
}

interface IMoodImage {
  url: string;
}

interface IMoodPrediction {
  id: string;
  description: string;
  image: IMoodImage;
}

export interface IMood {
  id: string;
  image: IMoodImage;
  name: Mood;
  predictions: IMoodPrediction[];
}

export interface ISelectedMood {
  name: Mood;
  timestamp: number;
  predictionId: number;
}
