import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { RoutePaths } from 'common-types';
import { pagesData } from 'data/navigation';
import { memo, SyntheticEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const BottomNav = memo(() => {
  const location = useLocation();
  const [value, setValue] = useState(RoutePaths.HOME);

  const handleChangeRoute = (_: SyntheticEvent<Element, Event>, newValue: RoutePaths): void => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.pathname.includes(RoutePaths.STYLE_PLAN + '/')) {
      setValue(RoutePaths.STYLE_PLAN);
      return;
    }

    setValue(location.pathname as RoutePaths);
  }, [location.pathname]);

  return (
    <>
      <BottomNavigation showLabels value={value} onChange={handleChangeRoute} sx={{ bgcolor: 'secondary.light' }}>
        {pagesData.map(({ title, href, Icon }) => (
          <BottomNavigationAction
            key={title}
            label={title}
            value={href}
            icon={<Icon />}
            component={Link}
            to={href}
            sx={{
              '& .MuiBottomNavigationAction-label': {
                fontSize: 10,
                mt: 1,
                fontWeight: 500,
                '&.Mui-selected': {
                  fontSize: 10,
                },
              },
            }}
          />
        ))}
      </BottomNavigation>
    </>
  );
});
