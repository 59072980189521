import { Box, useMediaQuery } from '@mui/material';
import { RoutePaths } from 'common-types';
import { BottomNav } from 'components/bottom-nav';
import { DrawerMenu } from 'components/drawer';
import { Header } from 'components/header';
import { Review } from 'components/modals/review';
import { ProfileDialog } from 'components/profile-dialog';
import { SessionWatcher } from 'components/session-watcher';
import { TutorialModalCard } from 'components/tutorial-modal-card';
import theme from 'providers/theme/theme';
import { Outlet, useLocation } from 'react-router-dom';

const pagesWithDifferentBg = [RoutePaths.PROFILE, RoutePaths.SUBSCRIPTION_INFO];

export const LayoutMain = (): JSX.Element => {
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();

  return (
    <Box
      component="main"
      display="flex"
      pb={{ xs: 20, lg: 0 }}
      pt={{ xs: 15, lg: 0 }}
      bgcolor={{
        xs: pagesWithDifferentBg.includes(location.pathname as RoutePaths) ? 'custom.baseBg' : 'common.white',
        lg: 'common.white',
      }}
    >
      {isMediumAndDown ? (
        <>
          <Header />
          <Box
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            zIndex={1000}
            bgcolor="secondary.light"
            borderTop={`1px solid ${theme.palette.custom.lightGrey}`}
          >
            <BottomNav />
          </Box>
        </>
      ) : (
        <DrawerMenu />
      )}
      <Box
        minHeight="100vh"
        width="100%"
        bgcolor={{ xs: 'common.white', lg: 'custom.baseBg' }}
        pl={{ lg: 6, xl: 10 }}
        pr={{ lg: 6 }}
        py={{ lg: 8 }}
      >
        <Outlet />
      </Box>
      <ProfileDialog />
      <TutorialModalCard />
      <Review />
      <SessionWatcher />
    </Box>
  );
};
