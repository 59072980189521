import { Box, Grid, Typography } from '@mui/material';
import { Fonts, RoutePaths } from 'common-types';
import { pagesData } from 'data/navigation';
import { memo, MouseEvent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAnalytics } from 'services/analytics';

import { navLinkStyles } from '../../styles';

export const LinksList = memo(() => {
  const { trackEvent } = useAnalytics();
  const location = useLocation();

  const isNavLinkActive = (href: string): boolean => {
    const isExactMatch = location?.pathname === href;
    const isPartialMatch = location?.pathname.includes(href) && href !== RoutePaths.HOME;

    return isExactMatch || isPartialMatch;
  };

  const handleNavClick = (_: MouseEvent<HTMLElement>, screenName: string): void => {
    if (location.pathname === RoutePaths.HOME && screenName) {
      trackEvent('home_screen_button_tap', {
        button: screenName.toLowerCase(),
      });
    }
  };

  return (
    <Grid container component="ul" direction="column">
      {pagesData.map(({ title, href, Icon }) => {
        const isActive = isNavLinkActive(href);

        return (
          <Grid item component="li" key={title} bgcolor={isActive ? 'primary.main' : 'common.white'}>
            <Box
              component={NavLink}
              onClick={(e: MouseEvent<HTMLElement>): void => handleNavClick(e, title)}
              to={href}
              sx={[
                navLinkStyles,
                {
                  pointerEvents: isActive ? 'none' : 'auto',
                },
              ]}
            >
              <Box component="span" display="flex" color={isActive ? 'common.white' : 'custom.iconGrey'}>
                <Icon />
              </Box>
              <Box component="span" ml={4}>
                <Typography
                  variant="body1"
                  fontFamily={`${Fonts.TITLE}, sans-serif`}
                  fontWeight={500}
                  color={isActive ? 'common.white' : 'custom.textBlack'}
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
});
