import { ErrorType } from 'common-types';

import { ReactComponent as DownloadGuideIcon } from '../assets/download-guide-icon.svg';
import { ReactComponent as GuidesIcon } from '../assets/guides-icon.svg';
import { ReactComponent as SignInIcon } from '../assets/sign-in-icon.svg';
import { ReactComponent as SubscriptionIcon } from '../assets/subscription-icon.svg';

export const errorTitleMap: Record<ErrorType, string> = {
  [ErrorType.EMAIL]: 'Email or password is incorrect',
  [ErrorType.GUIDES]: 'Something went wrong...',
  [ErrorType.DOWNLOAD_GUIDE]: 'Downloading Failed',
  [ErrorType.SUBSCRIPTION]: 'Your Subscription data is currently not available',
};

export const errorDescriptionMap: Record<ErrorType, string> = {
  [ErrorType.EMAIL]: 'Please double-check your credentials and try again',
  [ErrorType.GUIDES]: 'Guides are currently not available. Please, try again later or contact our Support Team',
  [ErrorType.DOWNLOAD_GUIDE]: 'Please, try again later or contact our Support Team',
  [ErrorType.SUBSCRIPTION]: 'Please, try again later or contact our Support Team',
};

export const errorButtonMap: Record<ErrorType, string> = {
  [ErrorType.EMAIL]: 'Try again',
  [ErrorType.GUIDES]: 'Got it',
  [ErrorType.DOWNLOAD_GUIDE]: 'Got it',
  [ErrorType.SUBSCRIPTION]: 'Got it',
};

export const errorIconMap: Record<ErrorType, JSX.Element> = {
  [ErrorType.EMAIL]: <SignInIcon />,
  [ErrorType.GUIDES]: <GuidesIcon />,
  [ErrorType.DOWNLOAD_GUIDE]: <DownloadGuideIcon />,
  [ErrorType.SUBSCRIPTION]: <SubscriptionIcon />,
};
