import { ReminderValue } from 'common-types';

export const reminderValueMap = {
  [ReminderValue.SMS]: 'SMS',
  [ReminderValue.EMAIL]: 'Email',
  [ReminderValue.WHATSAPP]: 'WhatsApp',
  [ReminderValue.NO_REMIND]: 'Without reminder',
};

export const modifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 8],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
      padding: 8,
    },
  },
];
