import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import format from 'date-fns/format';
import { memo } from 'react';

import { ReactComponent as NoteIcon } from './assets/note-icon.svg';
import { ISingleNote } from './utils/types';

export const SingleNote = memo<ISingleNote>(({ title, text, timestamp, index, handleClickToEditNote }) => (
  <Grid
    container
    onClick={(): void => handleClickToEditNote({ title, text }, index)}
    mt={2}
    height={70}
    position="relative"
    flexWrap="nowrap"
    sx={{ cursor: 'pointer' }}
    spacing={2}
  >
    <Grid item>
      <Box width={62} height={62}>
        <NoteIcon />
      </Box>
    </Grid>
    <Grid item xs mt={3.5}>
      <Stack height="100%">
        <Typography
          variant="body1"
          fontWeight={700}
          mb={1}
          sx={{ width: 260, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        >
          {title || 'Note'}
        </Typography>
        <Grid container justifyContent="space-between" flexWrap="nowrap" alignItems="center">
          <Grid item container>
            <Typography
              variant="body2"
              color="custom.textGrey"
              sx={{ width: 160, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            >
              {text}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Typography variant="caption" color="custom.textGrey" ml={3} pr={1}>
              {format(timestamp * 1000 || 1674065538, 'dd MMM yyyy')}
            </Typography>
          </Grid>
        </Grid>
        <Box mt="auto">
          <Divider />
        </Box>
      </Stack>
    </Grid>
  </Grid>
));
