import { RoutePaths } from 'common-types';
import { LayoutBooking } from 'layout/booking';
import { LayoutMain } from 'layout/main';

import { LayoutEmpty } from '../layout/empty';

export const routesLayoutMap: Partial<Record<RoutePaths, JSX.Element>> = {
  [RoutePaths.HOME]: <LayoutMain />,
  [RoutePaths.MY_ACCOUNT]: <LayoutMain />,
  [RoutePaths.STYLE_PLAN]: <LayoutMain />,
  [RoutePaths.STYLE_PLAN_DAY]: <LayoutMain />,
  [RoutePaths.GUIDES]: <LayoutMain />,
  [RoutePaths.PROFILE]: <LayoutMain />,
  [RoutePaths.STYLIST]: <LayoutMain />,
  [RoutePaths.BOOKING]: <LayoutBooking />,
  [RoutePaths.SUBSCRIPTION_INFO]: <LayoutMain />,
  [RoutePaths.ONBOARDING]: <LayoutBooking />,
  [RoutePaths.WARDROBE_REVIEW]: <LayoutMain />,
  [RoutePaths.SESSION_CONFIRMATION]: <LayoutEmpty />,
  [RoutePaths.SEASONAL_CAPSULE]: <LayoutMain />,
  [RoutePaths.COLOR_PALETTE]: <LayoutMain />,
};
