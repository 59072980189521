import { UserPermissionStatus } from 'common-types';
import { StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ILocalStorageStore } from './types';

export const localStorageSlice: StateCreator<ILocalStorageStore, [], [['zustand/persist', ILocalStorageStore], ...[]]> =
  persist(
    (set) => ({
      userPermission: UserPermissionStatus.LOGGED_OUT,
      isDevModeActive: true,
      token: '',
      userId: null,
      isUserLoggedIn: null,
      domain: '',
      isUserOnboardingDone: false,
      isUserOnboardingStarted: false,
      isUserGuidesOnboardingDone: false,
      isUserStylistOnboardingDone: false,
      isUserStylePlanOnboardingDone: false,
      isOriginalOnboardingDone: false,
      isReviewModalShown: false,
      isModalAlreadyShown: false,
      cancelFlowABTestGroup: null,
      updateCancelFlowABTestGroup: (abTestGroup) => set({ cancelFlowABTestGroup: abTestGroup }),
      updateIsModalAlreadyShown: (isModalAlreadyShown) => set({ isModalAlreadyShown }),
      setIsReviewModalShown: (isReviewModalShown) => set({ isReviewModalShown }),
      updateOriginalOnboardingStatus: (value) => set({ isOriginalOnboardingDone: value }),
      setUserPermission: (userPermission) => set({ userPermission }),
      setIsDevModeActive: (isDevModeActive) => set({ isDevModeActive }),
      setToken: (token) => set({ token }),
      setUserId: (userId) => set({ userId }),
      setIsUserLoggedIn: (isUserLoggedIn) => set({ isUserLoggedIn }),
      setDomain: (domain) => set({ domain }),
      setIsUserOnboardingDone: (isUserOnboardingDone) => set({ isUserOnboardingDone }),
      setIsUserOnboardingStarted: (isUserOnboardingStarted) => set({ isUserOnboardingStarted }),
      setIsUserGuidesOnboardingDone: (isUserGuidesOnboardingDone) => set({ isUserGuidesOnboardingDone }),
      setIsUserStylistOnboardingDone: (isUserStylistOnboardingDone) => set({ isUserStylistOnboardingDone }),
      setIsUserStylePlanOnboardingDone: (isUserStylePlanOnboardingDone) => set({ isUserStylePlanOnboardingDone }),
      logout: (): void => {
        // @ts-ignore
        window.zE('messenger', 'logoutUser');

        set({
          userPermission: UserPermissionStatus.LOGGED_OUT,
          token: '',
          userId: null,
          domain: '',
          isUserLoggedIn: null,
          isUserOnboardingDone: false,
          isUserOnboardingStarted: false,
          isUserGuidesOnboardingDone: false,
          isUserStylistOnboardingDone: false,
          isUserStylePlanOnboardingDone: false,
          isOriginalOnboardingDone: false,
        });
      },
      userFullOnboardingDone: () =>
        set({
          isUserOnboardingDone: true,
          isUserOnboardingStarted: false,
          isUserGuidesOnboardingDone: true,
          isUserStylistOnboardingDone: true,
          isUserStylePlanOnboardingDone: true,
          isOriginalOnboardingDone: true,
        }),
    }),
    {
      name: 'app-local-storage',
      storage: createJSONStorage(() => localStorage),
    },
  );
