import { alpha } from '@mui/material';
import theme from 'providers/theme/theme';

export const popperStyles = {
  borderRadius: 2,
  maxWidth: 169,
  zIndex: 9999,
  bgcolor: 'background.paper',
  overflow: 'visible',
  boxShadow: `0px 4px 10px ${alpha(theme.palette.common.black, 0.1)}`,
  border: `1px solid ${theme.palette.primary.main}`,
  px: 3,
  pt: 4,
  pb: 3,
  '&::before': {
    content: '""',
    borderTop: `1px solid  ${theme.palette.primary.main}`,
    borderLeft: `1px solid  ${theme.palette.primary.main}`,
    display: 'block',
    position: 'absolute',
    top: -1,
    left: '50%',
    width: 16,
    height: 16,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) translateX(-50%) rotate(45deg)',
    zIndex: 0,
  },
  '& .MuiList-padding': {
    p: 0,
  },
};

export const telInputStyles = {
  '& .MuiInputBase-input': {
    borderLeft: '1px solid',
    borderColor: 'custom.borderGrey',
    px: 2,
  },
};

export const menuItemStyles = {
  py: 4,
  '&.Mui-selected': {
    backgroundColor: 'common.white',
    color: 'primary.main',
  },
};

export const iconHolderStyles = {
  position: 'absolute',
  top: '50%',
  right: 20,
  transform: 'translate(0, -40%)',
  color: 'custom.textGrey',
};
