import { alpha, Box, Divider, Grid, Modal, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/right-icon.svg';
import { RoutePaths } from 'common-types';
import theme from 'providers/theme/theme';
import { memo, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useAnalytics } from 'services/analytics';
import { useLocalStorageStore, useStore } from 'store';
import { shallow } from 'zustand/shallow';

import { LUMI_ONLY_SUPPORT_EMAIL, LUMI_PLACE_DOMAIN, SUPPORT_EMAIL } from '../../utils';
import { profileDialogData } from './utils/data';

export const ProfileDialog = memo(() => {
  const { trackEvent } = useAnalytics();

  const { isDrawerDialogOpen, setIsDrawerDialogOpen } = useStore(
    (state) => ({
      isDrawerDialogOpen: state.isDrawerDialogOpen,
      setIsDrawerDialogOpen: state.setIsDrawerDialogOpen,
    }),
    shallow,
  );

  const { logout, domain } = useLocalStorageStore(
    (state) => ({
      logout: state.logout,
      domain: state.domain,
    }),
    shallow,
  );

  const handleClose = (_: MouseEvent<HTMLElement>, screenName: string): void => {
    if (screenName) {
      trackEvent('account_button_tap', {
        button: screenName.toLowerCase(),
      });
    }

    setIsDrawerDialogOpen(false);
  };

  const handleLogOut = (): void => {
    logout();
    window.location.reload();
    handleClose(null, 'log_out');
  };

  return (
    <Modal
      keepMounted
      disableScrollLock
      open={isDrawerDialogOpen}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 24,
          left: 220,
          bgcolor: 'common.white',
          p: 6,
          borderRadius: 2.5,
          boxShadow: `0px 0px 10px 0px ${alpha(theme.palette.common.black, 0.1)}`,
        }}
      >
        <Box minWidth={290} pt={2}>
          <Stack>
            {profileDialogData.map(({ title, href, isRedirected = false, isNativeLink = false, Icon }, i) => {
              const isLastItem = i === profileDialogData.length - 1;

              return (
                <Grid
                  key={title}
                  container
                  component={isRedirected ? NavLink : isNativeLink ? 'a' : 'div'}
                  to={isRedirected ? href : null}
                  href={
                    isNativeLink
                      ? `mailto:${domain === LUMI_PLACE_DOMAIN ? LUMI_ONLY_SUPPORT_EMAIL : SUPPORT_EMAIL}`
                      : null
                  }
                  onClick={
                    href === RoutePaths.SIGN_OUT
                      ? handleLogOut
                      : (e: MouseEvent<HTMLElement>): void => handleClose(e, title)
                  }
                  mb={isLastItem ? 0 : 3}
                  sx={{
                    cursor: 'pointer',
                    '&:hover, &:focus-visible': {
                      '& .MuiGrid-root ': {
                        color: 'primary.main',
                      },
                    },
                  }}
                >
                  <Grid item color="custom.textGrey">
                    <Icon />
                  </Grid>
                  <Grid item xs pl={4}>
                    <Typography variant="body1" mb={isLastItem ? 0 : 3}>
                      {title}
                    </Typography>
                    {!isLastItem && <Divider />}
                  </Grid>
                  <Grid item color="custom.textGrey">
                    <ArrowRightIcon />
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
});
