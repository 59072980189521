import 'react-toastify/dist/ReactToastify.css';
import 'styles/toast.css';

import { init } from '@amplitude/analytics-browser';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ErrorModal } from 'components/modals/error';
import { ReactQueryProvider } from 'providers/react-query';
import { MuiThemeProvider } from 'providers/theme';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Router from 'router/router';
import { growthbook } from 'services/growthbook';
import { AMPLITUDE_ID } from 'utils';

import { ErrorBoundary } from './components/error-boundary';

export const App = (): JSX.Element => {
  useEffect(() => {
    init(AMPLITUDE_ID);
  }, []);

  return (
    <ReactQueryProvider>
      <GrowthBookProvider growthbook={growthbook}>
        <MuiThemeProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <Router />
              <ErrorModal />
              <ToastContainer
                autoClose={5000}
                position="top-center"
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                pauseOnHover
                icon={(data): JSX.Element => {
                  if (data.type === 'success') {
                    return <TaskAltIcon color="success" />;
                  }

                  return <ErrorOutlineIcon />;
                }}
              />
            </BrowserRouter>
          </ErrorBoundary>
        </MuiThemeProvider>
      </GrowthBookProvider>
    </ReactQueryProvider>
  );
};
