import { RoutePaths } from 'common-types';

import { ReactComponent as GuidesIcon } from './assets/guides-icon.svg';
import { ReactComponent as HomeIcon } from './assets/home-icon.svg';
import { ReactComponent as StyleIcon } from './assets/style-icon.svg';
import { ReactComponent as StylistIcon } from './assets/stylist-icon.svg';
import { IPageData } from './types';

export const pagesData: IPageData[] = [
  {
    title: 'Home',
    href: RoutePaths.HOME,
    Icon: HomeIcon,
  },
  {
    title: 'Style Plan',
    href: RoutePaths.STYLE_PLAN,
    Icon: StyleIcon,
  },
  {
    title: 'Guides',
    href: RoutePaths.GUIDES,
    Icon: GuidesIcon,
  },
  {
    title: 'Stylist',
    href: RoutePaths.STYLIST,
    Icon: StylistIcon,
  },
];
