export interface IMoodCard {
  title: string;
  image: string;
  handleClick?: () => void;
}

export enum PopperPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum AnchorRef {
  MOOD = 'mood',
  SPECIAL_OFFER = 'special-offer',
  NOTES = 'notes',
  RECENT = 'recent',
  YOUR_WAY = 'your-way',
  PROFILE = 'profile',
}
