import { Skeleton, Stack } from '@mui/material';
import { memo } from 'react';

export const FormSkeleton = memo(() => {
  return (
    <Stack gap={8} alignItems="center">
      <Skeleton variant="rounded" width="50%" height={40} />
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
    </Stack>
  );
});
