export const dialogStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 309,
  minWidth: { lg: 552 },
  width: { xs: 'calc(100% - 32px)', lg: 'calc((552 / 1440) * 100vw)' },
  bgcolor: 'common.white',
  borderRadius: 2.5,
  pt: 24,
  px: 17.5,
  pb: 15,
};
