import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, IconButton, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from 'providers/theme/theme';
import { memo, useEffect } from 'react';
import { useAnalytics } from 'services/analytics';
import { useStore } from 'store';
import { invertedButtonColor } from 'styles';
import { shallow } from 'zustand/shallow';

import RatingImg from '../../assets/rating.png';
import CoverImg from '../../assets/review-bg-1.png';
import TrustpilotImg from '../../assets/trustpilot.png';

export const Trustpilot = memo(() => {
  const { trackEvent } = useAnalytics();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('lg'));
  const { updateReviewModal, closeReviewModal } = useStore(
    (state) => ({
      updateReviewModal: state.updateReviewModal,
      closeReviewModal: state.closeReviewModal,
    }),
    shallow,
  );

  const handleClick = (): void => {
    trackEvent('trustpilot_popup_button_tap');
    updateReviewModal();
  };

  useEffect(() => {
    trackEvent('trustpilot_popup_shown');
  }, [trackEvent]);

  return (
    <Stack>
      <Box position="absolute" top={{ xs: 16, lg: 24 }} right={{ xs: 16, lg: 24 }}>
        <IconButton aria-label="close" size="small" onClick={closeReviewModal} sx={{ color: 'custom.textGrey' }}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
      {!isMediumAndDown && (
        <Box mb={{ xs: '-100px', lg: '-180px' }}>
          <Box
            component="img"
            src={CoverImg}
            sx={{
              width: 1,
              height: 1,
              objectFit: 'contain',
              objectPosition: 'top center',
            }}
          />
        </Box>
      )}
      <Box
        width={1}
        maxWidth={467}
        mx="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={15}
        px={4}
        sx={{ textAlign: 'center' }}
      >
        <Typography variant="body1" fontSize={24} fontWeight={700} mb={{ xs: 2, lg: 6 }} sx={{ order: 0 }}>
          We’re glad you enjoy it!
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={10}
          mb={{ xs: 8, lg: 10 }}
          sx={{
            order: { xs: 2, lg: 1 },
          }}
        >
          <Box component="img" src={TrustpilotImg} alt="Trustpilot" width={126} height={33} />
          <Box component="img" src={RatingImg} alt="Rating" width={94} height={18} />
        </Stack>
        <Typography
          variant="body1"
          fontSize={16}
          color="custom.textGrey"
          mb={{ xs: 4, lg: 6 }}
          sx={{
            order: { xs: 1, lg: 2 },
          }}
        >
          We will be very grateful to receive your feedback on Trustpilot.
        </Typography>
        <Button
          fullWidth
          LinkComponent={Link}
          href="https://www.trustpilot.com/review/lumi.place"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
          sx={[invertedButtonColor, { py: 4, order: 3 }]}
        >
          Share
        </Button>
      </Box>
    </Stack>
  );
});
