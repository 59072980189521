export const LATIN_COUNTRIES: string[] = [
  'ES',
  'CL',
  'AR',
  'CO',
  'VE',
  'MX',
  'PR',
  'BO',
  'CR',
  'DO',
  'EC',
  'GT',
  'HN',
  'PA',
  'PY',
  'PE',
  'UY',
  'SV',
];

export const EU_TAX_COUNTRIES: string[] = [
  'AT',
  'BG',
  'EE',
  'FR',
  'SK',
  'GB',
  'DE',
  'RO',
  'CY',
  'MT',
  'LU',
  'BE',
  'CZ',
  'ES',
  'LT',
  'LV',
  'NL',
  'IT',
  'SI',
  'IE',
  'PL',
  'PT',
  'FI',
  'GR',
  'DK',
  'HR',
  'SE',
  'HU',
  'CA',
  'BR',
];

export const PAYPAL_BLOCKED_COUNTRIES: string[] = ['DE', 'NL'];

export const EU_ALL_COUNTRIES: string[] = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'GE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];
