import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC } from 'react';
import { isDevelopment, isLocal } from 'utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const ReactQueryProvider: FC<{ children: JSX.Element }> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}
    {(isDevelopment() || isLocal()) && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);
