import { Box, Button, Modal, Stack, Typography, useMediaQuery } from '@mui/material';
import { RoutePaths } from 'common-types';
import theme from 'providers/theme/theme';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { invertedButtonColor } from 'styles';
import { shallow } from 'zustand/shallow';

import { ReactComponent as ConfirmIcon } from '../../assets/confirm.svg';
import ModalImage from '../../assets/modal.jpg';
import { IConfirmationModal } from './utils/types';

export const ConfirmationModal = memo<IConfirmationModal>(({ isOpen }) => {
  const isTabletAndDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { updateSessionActionModal, setIsConfirmModalOpen, changeIsTutorialBlocked } = useStore(
    (state) => ({
      updateSessionActionModal: state.updateSessionActionModal,
      setIsConfirmModalOpen: state.setIsConfirmModalOpen,
      changeIsTutorialBlocked: state.changeIsTutorialBlocked,
    }),
    shallow,
  );

  const handleClose = (): void => {
    updateSessionActionModal({ isOpen: false, type: null });
    setIsConfirmModalOpen(false);
    changeIsTutorialBlocked(false);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        bgcolor="common.white"
        width="100%"
        maxWidth={650}
        borderRadius={{ lg: 5 }}
        sx={{
          transform: 'translate(-50%, -50%)',
          overflowY: 'auto',
          height: { xs: '100vh', lg: 'auto' },
          maxHeight: '100vh',
        }}
      >
        <Box display="flex" width={1} height={310}>
          <Box
            component="img"
            src={ModalImage}
            sx={{
              objectFit: 'cover',
              width: 1,
              height: 1,
              objectPosition: 'bottom center',
              borderRadius: { lg: '20px 20px 0 0' },
            }}
          />
        </Box>
        <Stack
          pt={{ xs: 12, lg: 6 }}
          pb={{ xs: 6, lg: 15 }}
          px={4}
          alignItems="center"
          gap={8}
          maxWidth={500}
          mx="auto"
        >
          {!isTabletAndDown && (
            <Box display="flex">
              <ConfirmIcon />
            </Box>
          )}
          <Box px={{ lg: 4 }}>
            <Typography variant="h2" component="p" fontSize={29} textAlign="center" mb={4}>
              The session is confirmed
            </Typography>
            <Typography variant="body1" fontSize={17} textAlign="center" color="custom.textGrey" mt={2}>
              We will notify you in 10 minutes before. To start the session, please welcome your stylist inside the chat
            </Typography>
          </Box>
          <Box width={1} maxWidth={375} mx="auto">
            <Button
              component={Link}
              to={RoutePaths.HOME}
              onClick={handleClose}
              fullWidth
              sx={[invertedButtonColor, { py: 4 }]}
            >
              Discover Lumi
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
});
