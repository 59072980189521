import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal } from '@mui/material';
import { AddSession } from 'components/booking/add-session';
import { memo } from 'react';

interface AddSessionModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddSessionModal = memo<AddSessionModalProps>(({ isOpen, handleClose }) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 1,
          maxWidth: 749,
          bgcolor: 'common.white',
          transform: 'translate(-50%, -50%)',
          maxHeight: { xs: 1, lg: 'calc(100% - 32px)' },
          overflowY: 'auto',
          borderRadius: { lg: 4 },
          outline: 'none',
        }}
      >
        <Box position="absolute" top={{ xs: 4, lg: 8 }} right={{ xs: 4, lg: 16 }} p={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <AddSession />
      </Box>
    </Modal>
  );
});
