export enum TextStyles {
  REGULAR = 'regular',
  BOLD = 'bold',
  TITLE = 'title',
}

export interface IProgressImage {
  url: string;
  size: {
    height: number;
    width: number;
  };
  link?: string;
}

export interface IProgressText {
  data: string;
  type: string;
  textStyle: TextStyles;
}

export interface IProgressInput {
  param: string;
  placeholder?: string;
}

export interface IProgressLink {
  label: string;
  url: string;
  type: string;
}

interface IProgressView {
  image?: IProgressImage;
  text: IProgressText;
  input?: IProgressInput;
  link?: IProgressLink;
}

interface IProgressScreens {
  title?: string;
  id: string;
  view: IProgressView[];
}

export interface IProgressParts {
  image: IProgressImage;
  timeToRead: number;
  topic: string;
  screens: IProgressScreens[];
  id: string;
}

export interface IProgressData {
  topic: string;
  id: number;
  description?: string;
  parts: IProgressParts[];
  image: IProgressImage;
}
