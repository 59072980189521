import { ReminderValue } from 'common-types';
import { getTime } from 'date-fns';
import { matchIsValidTel } from 'mui-tel-input';
import { z } from 'zod';

export const bookingSchema = z
  .object({
    email: z.string().email('Please provide a valid email address').optional(),
    phone: z.string().optional(),
    date: z
      .date({
        required_error: 'Please select a date',
        invalid_type_error: "That's not a date!",
      })
      .refine((date) => {
        if (!date) return false;
        const currentDate = new Date(new Date().toDateString());
        const selectedDate = new Date(date.toDateString());
        return getTime(selectedDate) >= getTime(currentDate);
      }, 'The date must be after today'),
    time: z.date({
      required_error: 'Please select a time',
      invalid_type_error: "That's not a time!",
    }),
    reminder: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const currentTime = getTime(new Date());
    const selectedTime = getTime(
      new Date(
        data.date.getFullYear(),
        data.date.getMonth(),
        data.date.getDate(),
        data.time.getHours(),
        data.time.getMinutes(),
      ),
    );
    if (selectedTime <= currentTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The time must be after now',
        path: ['time'],
      });
    }

    if (data.reminder === ReminderValue.SMS || data.reminder === ReminderValue.WHATSAPP) {
      if (!matchIsValidTel(data.phone)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please provide a phone number',
          path: ['phone'],
        });
      }
    }
  });

export const editBookingSchema = z
  .object({
    date: z
      .date({
        required_error: 'Please select a date',
        invalid_type_error: "That's not a date!",
      })
      .refine((date) => {
        if (!date) return false;
        const currentDate = new Date(new Date().toDateString());
        const selectedDate = new Date(date.toDateString());
        return getTime(selectedDate) >= getTime(currentDate);
      }, 'The date must be after today'),
    time: z.date({
      required_error: 'Please select a time',
      invalid_type_error: "That's not a time!",
    }),
    reminder: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const currentTime = getTime(new Date());
    const selectedTime = getTime(
      new Date(
        data.date.getFullYear(),
        data.date.getMonth(),
        data.date.getDate(),
        data.time.getHours(),
        data.time.getMinutes(),
      ),
    );
    if (selectedTime <= currentTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The time must be after now',
        path: ['time'],
      });
    }
  });

export type EditBookingFormData = z.infer<typeof editBookingSchema>;

export type BookingFormData = z.infer<typeof bookingSchema>;
