export interface IProduct {
  name: GuideType;
  status: GuideStatus;
  productId: string;
  price?: number;
}

export enum GuideType {
  HAIR = 'hair',
  COLOR = 'color',
  WARDROBE = 'wardrobe',
  BODY = 'body',
  STYLE = 'style',
  BODY_GUIDEBOOK = 'body_guidebook',
  SEXY_STYLE = 'sexy_style',
  MINIMALIST_WARDROBE = 'minimalist_wardrobe',
  FEMININE_CHIC = 'feminine_chic',
  AGELESS_STYLE = 'ageless_style',
}

export enum GuideStatus {
  BUY = 'buy',
  OPENED = 'opened',
  NOTIFY = 'request_notify',
  NOTIFIED = 'notify',
}
