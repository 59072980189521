import { EUnsubscribeSimplifiedStep } from 'common-types';
import { StateCreator } from 'zustand';

import { StoreType, TUnsubscribeSimplifiedStore } from './types';

export const unsubscribeSimplifiedSlice: StateCreator<StoreType, [], [], TUnsubscribeSimplifiedStore> = (set, get) => ({
  unsubscribeSimplifiedData: {
    activeStep: EUnsubscribeSimplifiedStep.ARE_YOU_SURE,
    activeReason: null,
    isError: false,
  },
  goToNextStep: (): void => {
    const data = get().unsubscribeSimplifiedData;
    if (data.activeStep === EUnsubscribeSimplifiedStep.ARE_YOU_SURE) {
      set({
        unsubscribeSimplifiedData: {
          ...data,
          activeStep: EUnsubscribeSimplifiedStep.REASON,
        },
      });
    }
  },
  goToPrevStep: (): void => {
    const data = get().unsubscribeSimplifiedData;
    if (data.activeStep === EUnsubscribeSimplifiedStep.REASON) {
      set({
        unsubscribeSimplifiedData: {
          ...data,
          activeStep: EUnsubscribeSimplifiedStep.ARE_YOU_SURE,
        },
      });
    }
  },
  changeActiveReason: (reason): void => {
    set({
      unsubscribeSimplifiedData: {
        ...get().unsubscribeSimplifiedData,
        activeReason: reason,
      },
    });
  },
  openErrorModal: (): void => {
    set({
      unsubscribeSimplifiedData: {
        ...get().unsubscribeSimplifiedData,
        isError: true,
      },
    });
  },
  clearUnsubscribeSimplifiedData: (): void => {
    set({
      unsubscribeSimplifiedData: {
        activeStep: EUnsubscribeSimplifiedStep.ARE_YOU_SURE,
        activeReason: null,
        isError: false,
      },
    });
  },
});
