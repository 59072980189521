import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from 'providers/theme/theme';
import { memo, useState } from 'react';
import { useAnalytics } from 'services/analytics';
import { useStore } from 'store';
import { RATING_STARS_COUNT } from 'utils';
import { shallow } from 'zustand/shallow';

import ReviewImg from '../../assets/review.png';
import { ReactComponent as StarFilledIcon } from '../../assets/star-filled.svg';

export const Rating = memo(() => {
  const { trackEvent } = useAnalytics();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [ratingHover, setRatingHover] = useState(null);

  const { reviewModal, updateReviewModal } = useStore(
    (state) => ({
      reviewModal: state.reviewModal,
      updateReviewModal: state.updateReviewModal,
    }),
    shallow,
  );

  const handleRatingHover = (index: number): void => {
    setRatingHover(index);
  };

  const handleRatingClick = (index: number): void => {
    trackEvent('review_popup_button_tap', { rating: index });
    updateReviewModal({ rating: index });
  };

  return (
    <Stack direction="row">
      {!isMediumAndDown && (
        <Box width="calc((350 / 749) * 100%)" borderRadius="16px 0 0 16px" overflow="hidden">
          <Box
            component="img"
            src={ReviewImg}
            draggable={false}
            alt="Review"
            width={1}
            height={1}
            sx={{ objectFit: 'cover' }}
          />
        </Box>
      )}
      <Box
        width={{ xs: 1, lg: 'calc((399 / 749) * 100%)' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={20}
        px={{ xs: 7, lg: 14 }}
      >
        <Typography variant="h2" component="p" fontSize={24} textAlign="center" mb={2}>
          Enjoying Lumi?
        </Typography>
        <Typography variant="body1" fontSize={17} color="custom.textGrey" textAlign="center" mb={12}>
          We’d love to know if you’re having
          <br /> a great experience.
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          maxWidth={208}
          width={1}
          mb={4}
          onMouseLeave={(): void => setRatingHover(0)}
        >
          {Array.from({ length: RATING_STARS_COUNT }).map((_, i) => (
            <Box
              role="button"
              key={i}
              display="flex"
              alignItems="center"
              onClick={(): void => handleRatingClick(i + 1)}
              onMouseEnter={(): void => handleRatingHover(i + 1)}
              sx={{
                cursor: 'pointer',
                color: (ratingHover || reviewModal.rating) > i ? 'primary.main' : 'transparent',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <StarFilledIcon />
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
});
