import axios, { AxiosInstance } from 'axios';
import { ICreateSessionData, IEditSessionData, ISession, ISessionLimit, Method } from 'common-types';
import { useLocalStorageStore } from 'store';

import { REACT_APP_CHIC_URL } from './config';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.BACKEND_API_HOST}`,
});

class ChicApi {
  private client = axiosInstance;

  private setGlobalHeader(headerName: string, value: string): void {
    axios.defaults.headers.common[headerName] = value;
  }

  private request = async <T>(url: string, method: Method, data?: unknown): Promise<T> => {
    const userId = useLocalStorageStore.getState().userId;
    const response = await axios.request<T>({
      url: `${REACT_APP_CHIC_URL}${url}`,
      method,
      data,
      headers: {
        'User-Id': userId,
      },
    });

    return response.data;
  };

  public setAuth = (token: string): void => {
    this.setGlobalHeader('Authorization', `Bearer ${token}`);
  };

  public addSession = async (data: ICreateSessionData): Promise<unknown> => {
    return this.request('/sessions', Method.POST, data);
  };

  public editSession = async (id: string, data: IEditSessionData): Promise<unknown> => {
    return this.request(`/sessions/${id}`, Method.PUT, data);
  };

  public getLastUserSession = async (userId: string): Promise<ISession> => {
    return this.request(`/sessions/user/${userId}`, Method.GET);
  };

  public getSessionLimit = async (userId: string): Promise<ISessionLimit[]> => {
    return this.request(`/sessions/${userId}/limits`, Method.GET);
  };

  public setSessionLimit = async (userId: string, data: ISessionLimit): Promise<unknown> => {
    return this.request(`/user-session-limit/set/${userId}`, Method.POST, data);
  };
}

const chicApi = new ChicApi();

export default chicApi;
