import { zodResolver } from '@hookform/resolvers/zod';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useGetUserData } from 'hooks/use-get-user-data';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAnalytics } from 'services/analytics';
import { useStore } from 'store';
import { invertedButtonColor } from 'styles';
import { shallow } from 'zustand/shallow';

import { ReviewFormData, reviewSchema } from '../../utils/schema';

export const FeedbackForm = memo(() => {
  const { trackEvent } = useAnalytics();
  const { userEmail } = useGetUserData();

  const { updateReviewModal, closeReviewModal } = useStore(
    (state) => ({
      updateReviewModal: state.updateReviewModal,
      closeReviewModal: state.closeReviewModal,
    }),
    shallow,
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(reviewSchema),
    mode: 'all',
  });

  const handleReviewSubmit = handleSubmit((data: ReviewFormData) => {
    trackEvent('review_popup_feedback_shared', {
      feedback: data.feedback,
      user_email: userEmail,
    });
    updateReviewModal();
  });

  const isDisabled = !isDirty || Object.keys(errors).length > 0;

  useEffect(() => {
    trackEvent('review_popup_feedback_shown');
  }, [trackEvent]);

  return (
    <>
      <Box position="absolute" top={{ xs: 16, lg: 24 }} right={{ xs: 16, lg: 24 }}>
        <IconButton aria-label="close" size="small" onClick={closeReviewModal} sx={{ color: 'custom.textGrey' }}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
      <form name="feedback-form" onSubmit={handleReviewSubmit}>
        <Stack px={4} maxWidth={467} mx="auto" sx={{ py: 15, textAlign: 'center' }}>
          <Typography variant="body1" fontSize={24} fontWeight={700} mb={2}>
            Thank you!
          </Typography>
          <Typography variant="body1" fontSize={16} mb={8} color="custom.textGrey">
            Please, let us know how we can improve your experience
          </Typography>
          <Box mb={6}>
            <Controller
              name="feedback"
              control={control}
              render={({ field }): JSX.Element => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={10}
                  placeholder="Enter your feedback"
                  helperText={errors.feedback?.message.toString()}
                  error={!!errors.feedback}
                />
              )}
            />
          </Box>
          <Button type="submit" fullWidth disabled={isDisabled} sx={[invertedButtonColor, { py: 4 }]}>
            Send
          </Button>
        </Stack>
      </form>
    </>
  );
});
