import * as Sentry from '@sentry/react';
import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { matchRoutes } from 'react-router';
import { createRoutesFromChildren, useLocation, useNavigationType } from 'react-router-dom';

import { App } from './app';
import { SENTRY_DSN } from './services/api/config';
import { isProduction } from './utils';

const container = document.getElementById('root');
const root = createRoot(container!);

if (isProduction()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.25,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // Facebook borked
      'fb_xd_fragment',
      /amplitude is not defined/i,
      /The user aborted a request/i,
      /Non-Error promise rejection captured/i,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
    ],
  });
}

root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <App />
  </Suspense>,
);
