import 'styles/calendar.css';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Box, Stack } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { BookingFormField, RoutePaths, SessionActionModalType } from 'common-types';
import { getUnixTime } from 'date-fns';
import { memo, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useAnalytics } from 'services/analytics';

interface IDateTime {
  pathname: string;
  type: SessionActionModalType;
}

export const DateTime = memo<IDateTime>(({ pathname, type }) => {
  const { trackEvent } = useAnalytics();

  const {
    control,
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const currentDate = getValues()[BookingFormField.DATE] || new Date();
  const isDateDirty = dirtyFields[BookingFormField.DATE];
  const isTimeDirty = dirtyFields[BookingFormField.TIME];

  const dateField: number | Date = useWatch({
    name: BookingFormField.DATE,
    control,
  });

  const timeField: number | Date = useWatch({
    name: BookingFormField.TIME,
    control,
  });

  useEffect(() => {
    const normalizedDate = new Date(dateField);
    const normalizedTime = new Date(timeField);

    if (isDateDirty || isTimeDirty) {
      trackEvent('booking_screen_time_field_filled', {
        timestamp: getUnixTime(
          new Date(
            normalizedDate.getFullYear(),
            normalizedDate.getMonth(),
            normalizedDate.getDate(),
            normalizedTime.getHours(),
            normalizedTime.getMinutes(),
          ),
        ),
        placement: pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
        type: type === SessionActionModalType.ADD ? 'book' : 'edit',
      });
    }
  }, [dateField, isDateDirty, isTimeDirty, pathname, timeField, trackEvent, type]);

  return (
    <Stack direction={{ lg: 'row' }} gap={{ xs: 4, lg: 3 }}>
      <Controller
        name={BookingFormField.DATE}
        control={control}
        defaultValue={currentDate}
        render={({ field }): JSX.Element => (
          <DatePicker
            {...field}
            inputRef={field.ref}
            disablePast
            slots={{
              openPickerIcon: CalendarTodayOutlinedIcon,
            }}
            slotProps={{
              textField: {
                error: !!errors.date,
                helperText: errors.date?.message.toString(),
              },
              openPickerIcon: {
                color: 'custom.textGrey',
              },
            }}
            sx={{
              '&.MuiDateCalendar-root': {
                width: 264,
                '& .MuiPickersDay-root:not(.Mui-selected)': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiPickersDay-root': {
                width: 32,
                height: 32,
              },
            }}
          />
        )}
      />
      <Box position="relative">
        <Controller
          name={BookingFormField.TIME}
          control={control}
          defaultValue={currentDate}
          render={({ field }): JSX.Element => (
            <TimePicker
              {...field}
              sx={{ width: 1 }}
              inputRef={field.ref}
              format="hh:mm a"
              slotProps={{
                textField: {
                  error: !!errors.time,
                  helperText: errors.time?.message.toString(),
                },
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );
});
