import { QuizHeight } from './quiz';

export enum OnboardingType {
  SCREEN = 'screen',
  QUESTION = 'question',
}

export enum OnboardingScreenType {
  IMAGE = 'image',
  TEXT_IMAGE = 'textImage',
}

export enum OnboardingQuiz {
  GOAL = 'user_goal',
  BUST = 'bust',
  WAIST = 'waist',
  HIPS = 'hips',
  HIGH_HIPS = 'high_hips',
  PREFERRED_OUTFIT = 'preferred_outfits',
}

export enum OnboardingQuizAnswer {
  INTRO = 'intro',
  GOAL = 'style_elevation_target',
  MEASUREMENT = 'measurement',
  PREFERRED_OUTFIT = 'preferred_outfit',
  RESULT = 'result',
}

export enum MeasurementSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export type Height = {
  label: QuizHeight;
  value: number;
};

export type OnboardingQuizAnswerType = {
  key: string;
  inputValue: string;
};

export type HeightValue = {
  measureSystem: MeasurementSystem;
  inputValues: Height[];
};

interface IOnboardingButton {
  text: string;
  active: boolean;
}

interface IOnboardingImage {
  url: string;
}

export interface IAnswer {
  id: string;
  text: string;
  boldText?: string;
}

export interface IOnboardingScreen {
  button?: IOnboardingButton;
  screenType?: OnboardingScreenType;
  text?: string;
  title?: string;
  image?: IOnboardingImage;
  answers?: IAnswer[];
  subtitle?: string;
  next?: string;
}

export interface IAnswersScreen {
  next: string;
  id: string;
  type: OnboardingType;
  screen: IOnboardingScreen;
}

export interface IOnboardingScreens {
  id: string;
  next: string | null;
  screen: IOnboardingScreen;
  answersScreens?: Record<string, IAnswersScreen>;
  type: OnboardingType;
}

export interface IOnboardingContent {
  firstScreenId: string;
  screens: Record<string, IOnboardingScreens>;
}
