export const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: { xs: 'calc(100% - 32px)', lg: 1 },
  maxWidth: 749,
  bgcolor: 'common.white',
  transform: 'translate(-50%, -50%)',
  maxHeight: { xs: 1, lg: 'calc(100% - 32px)' },
  overflowY: 'auto',
  pt: 15,
  px: 4,
  pb: { xs: 8, lg: 12 },
  outline: 'none',
  borderRadius: 4,
};
