import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common-types';
import { getFirebasePaidOptions } from 'services/firebase';

interface IPaidOptions {
  isInterviewOfferSuccess: boolean;
  isInterviewOfferError: boolean;
  isInterviewOfferLoading: boolean;
  isPaidOptionsActive: boolean;
}

export const useGetPaidOptions = (): IPaidOptions => {
  const { isSuccess, isError, isLoading, isLoadingError, data } = useQuery(
    [QueryKey.PAID_OPTIONS_STATUS],
    getFirebasePaidOptions,
  );

  return {
    isInterviewOfferSuccess: isSuccess,
    isInterviewOfferLoading: isLoading,
    isInterviewOfferError: isError || isLoadingError,
    isPaidOptionsActive: data?.isPaidOptionsActive,
  };
};
