import { GrowthBook } from '@growthbook/growthbook-react';
import { isProduction } from 'utils';

import {
  REACT_APP_GROWTHBOOK_API_HOST,
  REACT_APP_GROWTHBOOK_CLIENT_KEY_DEV,
  REACT_APP_GROWTHBOOK_CLIENT_KEY_PROD,
} from './config';

// Create a GrowthBook instance
export const growthbook = new GrowthBook({
  apiHost: REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: isProduction() ? REACT_APP_GROWTHBOOK_CLIENT_KEY_PROD : REACT_APP_GROWTHBOOK_CLIENT_KEY_DEV,
  subscribeToChanges: true,
  enableDevMode: !isProduction(),
});
