import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Collapse, Grid, IconButton, Skeleton, TextField, Typography, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { IUser, IUserNote, QueryKey } from 'common-types';
import { SingleNote } from 'components/single-note';
import getUnixTime from 'date-fns/getUnixTime';
import { useGetUserData } from 'hooks/use-get-user-data';
import { useSetUserData } from 'hooks/use-set-user-data';
import theme from 'providers/theme/theme';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAnalytics } from 'services/analytics';
import { MAX_NOTES_COUNT } from 'utils';

import { notesContainerStyles, saveButtonStyles } from './styles';

export const Notes = memo(() => {
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const { userNotes, isSuccess } = useGetUserData();
  const { mutateAsync, isError } = useSetUserData();

  const [isAddNoteExpanded, setIsAddNoteExpanded] = useState<boolean>(false);
  const [isNotesExpanded, setIsNotesExpanded] = useState<boolean>(false);
  const [noteEditingIndex, setNoteEditingIndex] = useState<number>(null);
  const [userNoteTitle, setUserNoteTitle] = useState<string>('Note');
  const [userNoteText, setUserNoteText] = useState<string>('');
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);

  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('lg'));

  const handleAddNote = useCallback(() => {
    trackEvent('notes_created');
    setIsAddNoteExpanded(false);
    setNoteEditingIndex(null);
    setUserNoteTitle('Note');
    setUserNoteText('');

    void mutateAsync({
      notes: [{ title: userNoteTitle, text: userNoteText, timestamp: getUnixTime(Date.now()) }, ...userNotes],
    });

    queryClient.setQueryData([QueryKey.USER_DATA], (prev: IUser) => {
      return {
        ...prev,
        notes: [{ title: userNoteTitle, text: userNoteText, timestamp: getUnixTime(Date.now()) }, ...userNotes],
      };
    });

    void queryClient.invalidateQueries({ queryKey: [QueryKey.USER_DATA, 'notes'] });
  }, [userNoteText, userNoteTitle, mutateAsync, queryClient]);

  const handleSaveNote = useCallback(() => {
    mutateAsync({
      notes: [
        ...userNotes.slice(0, noteEditingIndex),
        { title: userNoteTitle, text: userNoteText, timestamp: getUnixTime(Date.now()) },
        ...userNotes.slice(noteEditingIndex + 1),
      ],
    }).then(() => {
      setNoteEditingIndex(null);
    });
    setIsAddNoteExpanded(false);

    queryClient.setQueryData([QueryKey.USER_DATA], (prev: IUser) => {
      return {
        ...prev,
        notes: [
          ...prev.notes.slice(0, noteEditingIndex),
          { title: userNoteTitle || 'Note', text: userNoteText, timestamp: getUnixTime(Date.now()) },
          ...prev.notes.slice(noteEditingIndex + 1),
        ],
      };
    });

    void queryClient.invalidateQueries({ queryKey: [QueryKey.USER_DATA, 'notes'] });
  }, [userNoteText, userNoteTitle, noteEditingIndex, mutateAsync, queryClient]);

  const handleDeleteNote = useCallback(() => {
    if (noteEditingIndex === null) return;
    setIsAddNoteExpanded(false);
    setUserNoteText('');
    setUserNoteTitle('Note');

    mutateAsync({
      notes: [...userNotes.slice(0, noteEditingIndex), ...userNotes.slice(noteEditingIndex + 1)],
    }).then(() => {
      setNoteEditingIndex(null);
    });

    queryClient.setQueryData([QueryKey.USER_DATA], (prev: IUser) => {
      return {
        ...prev,
        notes: [...prev.notes.slice(0, noteEditingIndex), ...prev.notes.slice(noteEditingIndex + 1)],
      };
    });

    void queryClient.invalidateQueries({ queryKey: [QueryKey.USER_DATA, 'notes'] });
  }, [noteEditingIndex, mutateAsync, queryClient]);

  const handleClickToEditNote = useCallback((note: Omit<IUserNote, 'timestamp'>, index: number) => {
    setIsAddNoteExpanded(true);
    setNoteEditingIndex(index);
    setUserNoteText(note.text);
    setUserNoteTitle(note.title || 'Note');
  }, []);

  const handleClickCancelButton = useCallback(() => {
    setIsAddNoteExpanded(false);
    setNoteEditingIndex(null);
    setUserNoteText('');
    setUserNoteTitle('Note');
  }, []);

  const handleClickAddNote = useCallback(() => {
    setIsAddNoteExpanded(true);
    setNoteEditingIndex(null);
    setUserNoteText('');
    setUserNoteTitle('Note');
  }, []);

  const handleTitleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserNoteTitle(e.target.value);
  }, []);

  const handleStartEditTitle = useCallback(() => {
    setIsEditTitle(true);
  }, []);

  const handleFinishEditTitle = useCallback(() => {
    setIsEditTitle(false);
  }, []);

  const handleExpandNotesClick = (): void => {
    trackEvent('notes_tap');
    setIsNotesExpanded(!isNotesExpanded);
  };

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong. Please try again later.');
    }
  }, [isError]);

  return (
    <Box
      width="100%"
      minWidth={380}
      bgcolor="common.white"
      borderRadius={{ lg: 2.5 }}
      minHeight={67}
      p={{ xs: 4, lg: 6 }}
      position={{ xs: 'absolute', lg: 'static' }}
    >
      {isSuccess ? (
        <>
          <Collapse in={!isAddNoteExpanded}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h4" component="h3">
                  Notes
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton
                      size="small"
                      disabled={userNotes?.length >= MAX_NOTES_COUNT}
                      onClick={handleClickAddNote}
                    >
                      <AddCircleIcon color={userNotes?.length === MAX_NOTES_COUNT ? 'disabled' : 'primary'} />
                    </IconButton>
                  </Grid>
                  {!isMediumAndDown && (
                    <Grid item ml={2}>
                      {userNotes?.length > 0 && (
                        <IconButton size="small" onClick={handleExpandNotesClick}>
                          {isNotesExpanded ? (
                            <KeyboardArrowUpIcon fontSize="small" />
                          ) : (
                            <KeyboardArrowDownIcon fontSize="small" />
                          )}
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={isMediumAndDown ? true : isNotesExpanded}>
              <Box maxHeight={{ xs: 'calc(41vh - 64px)', lg: 300 }} sx={notesContainerStyles}>
                {userNotes?.map(({ title, text, timestamp }, index) => (
                  <SingleNote
                    key={title + timestamp}
                    handleClickToEditNote={handleClickToEditNote}
                    index={index}
                    timestamp={timestamp}
                    title={title}
                    text={text}
                  />
                ))}
              </Box>
            </Collapse>
          </Collapse>
          <Collapse in={isAddNoteExpanded}>
            <>
              <Box>
                <Box position="relative" mb={4}>
                  <Typography
                    color="custom.textToChangeColor"
                    sx={{
                      visibility: isEditTitle ? 'hidden' : 'visible',
                      pb: 1,
                    }}
                  >
                    <Typography variant="h4" component="span" sx={{ borderBottom: '1px dashed currentColor' }}>
                      {userNoteTitle}
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    value={userNoteTitle}
                    onClick={handleStartEditTitle}
                    onChange={handleTitleChange}
                    onBlur={handleFinishEditTitle}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                      zIndex: 1,
                      opacity: isEditTitle ? '1' : '0',
                      '& .MuiInputBase-input': {
                        p: 0,
                      },
                    }}
                  />
                </Box>
                <Box mb={4}>
                  <TextField
                    value={userNoteText}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
                      setUserNoteText(e.target.value)
                    }
                    fullWidth
                    multiline
                    rows={isMediumAndDown ? 5 : 7}
                  />
                </Box>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Button
                          disabled={
                            userNoteText?.length === 0 ||
                            (userNoteTitle === userNotes[noteEditingIndex]?.title &&
                              userNoteText === userNotes[noteEditingIndex]?.text)
                          }
                          onClick={noteEditingIndex !== null ? handleSaveNote : handleAddNote}
                          sx={saveButtonStyles}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item ml={6}>
                        <Button
                          variant="outlined"
                          onClick={handleClickCancelButton}
                          sx={{ maxWidth: 87, height: 28, color: 'primary.main', borderColor: 'primary.main' }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton size="small" onClick={handleDeleteNote} disabled={noteEditingIndex === null}>
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </>
          </Collapse>
        </>
      ) : (
        <Skeleton variant="rounded" animation="wave" height={34} />
      )}
    </Box>
  );
});
