import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  BookingFormField,
  HttpCodeSessionException,
  QueryKey,
  ReminderValue,
  SessionModalStatus,
  SessionStatus,
} from 'common-types';
import { getUnixTime } from 'date-fns';
import us from 'date-fns/locale/en-US';
import { useEditMeet } from 'hooks/use-edit-meet';
import { useGetPaidOptions } from 'hooks/use-get-paid-options';
import { useGetUserData } from 'hooks/use-get-user-data';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAnalytics } from 'services/analytics';
import { useLocalStorageStore, useSessionStorageStore, useStore } from 'store';
import { DATE_NOW } from 'utils';
import { shallow } from 'zustand/shallow';

import { EditSessionForm } from '../components/edit-session-form';
import { EditBookingFormData, editBookingSchema } from '../utils/schema';
import { dialogStyles } from './styles';

interface EditSessionProps {
  isOpen: boolean;
  meetId: string;
  datetime: Date | number | null;
  reminder: ReminderValue;
  handleClose: () => void;
}

export const EditSession = memo<EditSessionProps>(({ isOpen, meetId, datetime, reminder, handleClose }) => {
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const { userId, userEmail } = useGetUserData();
  const { mutateAsync: editMeetAsync, isError: isEditMeetError, error: editMeetError, isLoading } = useEditMeet();
  const { isPaidOptionsActive } = useGetPaidOptions();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isEditMeetLoading = isLoading;

  const { updateSessionModal, closeSessionActionModal } = useStore(
    (state) => ({
      updateSessionModal: state.updateSessionModal,
      closeSessionActionModal: state.closeSessionActionModal,
    }),
    shallow,
  );

  const { updateIsModalAlreadyShown } = useLocalStorageStore(
    (state) => ({
      updateIsModalAlreadyShown: state.updateIsModalAlreadyShown,
    }),
    shallow,
  );

  const { updateIsPrevSessionCanceled } = useSessionStorageStore((state) => ({
    updateIsPrevSessionCanceled: state.updateIsPrevSessionCanceled,
  }));

  const date = useMemo(() => (datetime ? new Date(datetime) : DATE_NOW), [datetime]);

  const methods = useForm({
    resolver: zodResolver(editBookingSchema),
    mode: 'all',
    defaultValues: {
      [BookingFormField.DATE]: date,
      [BookingFormField.TIME]: date,
      [BookingFormField.REMINDER]: reminder || '',
    },
  });

  useEffect(() => {
    methods.setValue(BookingFormField.DATE, date);
    methods.setValue(BookingFormField.TIME, date);
    methods.setValue(BookingFormField.REMINDER, reminder || '');
  }, [date, reminder, methods]);

  const handleFormSubmit = methods.handleSubmit(async (data: EditBookingFormData): Promise<void> => {
    trackEvent('booking_screen_button_clicked', {
      user_email: userEmail,
      user_id: userId,
      reminder: Boolean(data[BookingFormField.REMINDER]),
      reminder_type: data[BookingFormField.REMINDER],
      placement: 'stylist_screen',
      type: 'edit',
      button: 'update',
    });

    const formDataTime = new Date(
      data[BookingFormField.DATE].getFullYear(),
      data[BookingFormField.DATE].getMonth(),
      data[BookingFormField.DATE].getDate(),
      data[BookingFormField.TIME].getHours(),
      data[BookingFormField.TIME].getMinutes(),
    );

    try {
      await editMeetAsync({
        meetId,
        data: {
          timestamp: getUnixTime(formDataTime),
          reminderType: data[BookingFormField.REMINDER] as ReminderValue,
          secondsFromUtc: formDataTime.getTimezoneOffset() * 60 * -1,
        },
      });
    } catch (error: any) {
      if (
        error?.response?.data?.code !== HttpCodeSessionException.SESSION_LIMIT &&
        error?.response?.data?.code !== HttpCodeSessionException.SESSION_IS_STARTED
      ) {
        Sentry.captureException(`Booking form error! Error: ${error}`);
      }

      if (error?.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT) {
        closeSessionActionModal();
        updateSessionModal({ isOpen: true, type: SessionModalStatus.REACH_LIMIT });
      }

      if (error?.response?.data?.code === HttpCodeSessionException.SESSION_IS_STARTED) {
        closeSessionActionModal();
        updateSessionModal({ isOpen: true, type: SessionModalStatus.ALREADY_STARTED });
      }
    }

    handleClose();
    updateIsModalAlreadyShown(false);
    void queryClient.invalidateQueries([QueryKey.LAST_USER_SESSION]);
  });

  const handleOpenDialog = (): void => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    trackEvent('session_popup_button_click', { button: 'no' });
    closeSessionActionModal();
    setIsDialogOpen(false);
  };

  const handleCancelSession = useCallback(async () => {
    trackEvent('session_cancel_clicked');

    handleOpenDialog();
  }, [trackEvent]);

  const handleConfirmCancelSession = async (): Promise<void> => {
    trackEvent('session_popup_button_click', { button: 'yes' });
    trackEvent('booking_screen_button_clicked', {
      user_email: userEmail,
      user_id: userId,
      reminder: Boolean(reminder),
      reminder_type: reminder,
      placement: 'stylist_screen',
      type: 'edit',
      button: 'cancel_session',
    });

    handleCloseDialog();

    await editMeetAsync({
      meetId,
      data: {
        status: SessionStatus.CANCELED,
      },
    });

    updateIsPrevSessionCanceled(true);

    queryClient.invalidateQueries([QueryKey.LAST_USER_SESSION]);
  };

  // TODO: Remove this
  useEffect(() => {
    if (
      isEditMeetError &&
      editMeetError instanceof AxiosError &&
      editMeetError.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT
    ) {
      closeSessionActionModal();
      updateSessionModal({ isOpen: true, type: SessionModalStatus.REACH_LIMIT });
    }

    if (
      isEditMeetError &&
      editMeetError instanceof AxiosError &&
      editMeetError.response?.data?.code === HttpCodeSessionException.SESSION_IS_STARTED
    ) {
      closeSessionActionModal();
      updateSessionModal({ isOpen: true, type: SessionModalStatus.ALREADY_STARTED });
    }
  }, [updateSessionModal, closeSessionActionModal, isEditMeetError, editMeetError]);

  useEffect(() => {
    if (isOpen) {
      trackEvent('booking_screen_shown', { source: 'web', placement: 'stylist_screen', type: 'edit', once: true });
    }
  }, [isOpen, trackEvent]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={us}>
        <Box component="form" onSubmit={handleFormSubmit}>
          <FormProvider {...methods}>
            <EditSessionForm
              userEmail={userEmail}
              isEditMeetLoading={isEditMeetLoading}
              isPaidOptionsActive={isPaidOptionsActive}
              handleCancelSession={handleCancelSession}
            />
          </FormProvider>
        </Box>
      </LocalizationProvider>
      <Modal open={isDialogOpen} onClose={handleCloseDialog}>
        <Box sx={dialogStyles}>
          <Typography variant="h2" component="p" textAlign="center">
            Are you sure you want to cancel this session?
          </Typography>
          <Stack direction="row" justifyContent="center" gap={2} sx={{ mt: 10 }}>
            <Button fullWidth sx={{ py: 4 }} onClick={handleCloseDialog}>
              No
            </Button>
            <Button fullWidth sx={{ py: 4 }} onClick={handleConfirmCancelSession} color="error">
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
});
