import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  BookingFormField,
  HttpCodeSessionException,
  QueryKey,
  ReminderValue,
  RoutePaths,
  SessionModalStatus,
} from 'common-types';
import { getUnixTime } from 'date-fns';
import us from 'date-fns/locale/en-US';
import { useGetLastUserSession } from 'hooks/use-get-last-user-session';
import { useGetPaidOptions } from 'hooks/use-get-paid-options';
import { useGetUserData } from 'hooks/use-get-user-data';
import { useSetNewMeet } from 'hooks/use-set-new-meet';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'services/analytics';
import { useLocalStorageStore, useStore } from 'store';
import { ONE_DAY } from 'utils';
import { shallow } from 'zustand/shallow';

import { useGetSessionLimit } from '../../../hooks/use-get-session-limit';
import { BookingForm } from '../components/add-session-form';
import { FormSkeleton } from '../components/skeleton';
import { BookingFormData, bookingSchema } from '../utils/schema';
import { SESSION_TYPE_MAP } from './utils/data';

interface IAddSessionProps {
  isPage?: boolean;
  sessionType?: string;
}

export const AddSession: FC<IAddSessionProps> = ({ isPage, sessionType }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { isGetSessionLimitSuccess } = useGetSessionLimit();
  const { isLastUserSessionLoading } = useGetLastUserSession();
  const { isSuccess, userEmail, userId } = useGetUserData();
  const {
    mutateAsync: addStylistMeetAsync,
    isError: isMeetAddedError,
    error: addMeetError,
    isLoading,
  } = useSetNewMeet();
  const { isPaidOptionsActive } = useGetPaidOptions();

  const { updateSessionModal, closeSessionActionModal } = useStore(
    (state) => ({
      updateSessionModal: state.updateSessionModal,
      closeSessionActionModal: state.closeSessionActionModal,
    }),
    shallow,
  );

  const { updateIsModalAlreadyShown } = useLocalStorageStore(
    (state) => ({
      updateIsModalAlreadyShown: state.updateIsModalAlreadyShown,
    }),
    shallow,
  );

  const methods = useForm({
    resolver: zodResolver(bookingSchema),
    mode: 'all',
    defaultValues: {
      [BookingFormField.EMAIL]: userEmail || '',
      [BookingFormField.PHONE]: '',
      [BookingFormField.DATE]: new Date(Date.now() + ONE_DAY),
      [BookingFormField.TIME]: new Date(),
      [BookingFormField.REMINDER]: '',
    },
  });

  const handleFormSubmit = methods.handleSubmit(async (data: BookingFormData): Promise<void> => {
    trackEvent('booking_screen_button_clicked', {
      user_email: data[BookingFormField.EMAIL],
      user_id: userId,
      reminder: Boolean(data[BookingFormField.REMINDER]),
      reminder_type: data[BookingFormField.REMINDER],
      placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
      type: 'book',
      button: 'book',
    });

    const formDataTime = new Date(
      data[BookingFormField.DATE].getFullYear(),
      data[BookingFormField.DATE].getMonth(),
      data[BookingFormField.DATE].getDate(),
      data[BookingFormField.TIME].getHours(),
      data[BookingFormField.TIME].getMinutes(),
    );

    try {
      await addStylistMeetAsync({
        userId,
        email: data[BookingFormField.EMAIL] || userEmail,
        phone:
          data[BookingFormField.REMINDER] === ReminderValue.SMS ||
          data[BookingFormField.REMINDER] === ReminderValue.WHATSAPP
            ? data[BookingFormField.PHONE].replace(/\D/g, '')
            : undefined,
        timestamp: getUnixTime(formDataTime),
        secondsFromUtc: data[BookingFormField.DATE].getTimezoneOffset() * 60 * -1,
        reminderType: (data[BookingFormField.REMINDER] as ReminderValue) || ReminderValue.NO_REMIND,
        sessionType: sessionType ? SESSION_TYPE_MAP[sessionType] ?? sessionType : undefined,
        metadata: {
          created_by: 'user',
          platform_created: 'lumi_web',
        },
      });
    } catch (error: any) {
      if (
        error?.response?.data?.code !== HttpCodeSessionException.SESSION_LIMIT &&
        error?.response?.data?.code !== HttpCodeSessionException.SESSION_IS_STARTED
      ) {
        Sentry.captureException(`Booking form error! Error: ${error}`);
      }

      if (error?.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT) {
        closeSessionActionModal();
        updateSessionModal({ isOpen: true, type: SessionModalStatus.REACH_LIMIT });
      }
    }

    closeSessionActionModal();
    updateIsModalAlreadyShown(false);
    void queryClient.invalidateQueries([QueryKey.LAST_USER_SESSION]);

    if (isPage) {
      navigate(RoutePaths.HOME);
    }
  });

  useEffect(() => {
    if (isSuccess) {
      methods.setValue(BookingFormField.EMAIL, userEmail || '', { shouldValidate: true });
    }
  }, [isSuccess, methods, userEmail]);

  // TODO: Remove this
  useEffect(() => {
    if (
      isMeetAddedError &&
      addMeetError instanceof AxiosError &&
      addMeetError.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT
    ) {
      closeSessionActionModal();
      updateSessionModal({ isOpen: true, type: SessionModalStatus.REACH_LIMIT });
    }
  }, [isMeetAddedError, addMeetError, updateSessionModal, closeSessionActionModal]);

  useEffect(() => {
    trackEvent('booking_screen_shown', {
      placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
      type: 'book',
      once: true,
    });
  }, [location.pathname, trackEvent]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={us}>
      <Box component="section" maxWidth={504} mx="auto" py={{ xs: 8, lg: 12 }} px={4}>
        {isSuccess && !isLastUserSessionLoading && isGetSessionLimitSuccess ? (
          <>
            <Typography variant="h1" fontSize={25} textAlign="center" mb={{ xs: 4, lg: 6 }}>
              Book a session
            </Typography>
            <Typography variant="body1" fontSize={16} textAlign="center" mb={{ xs: 8, lg: 12 }}>
              Our stylist has some ideas for your first customized outfit. Don't put off your session and start
              improving your closet now
            </Typography>
            <Box component="form" onSubmit={handleFormSubmit}>
              <FormProvider {...methods}>
                <BookingForm isPaidOptionsActive={isPaidOptionsActive} isMeetLoading={isLoading} />
              </FormProvider>
            </Box>
          </>
        ) : (
          <FormSkeleton />
        )}
      </Box>
    </LocalizationProvider>
  );
};
