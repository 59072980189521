import { Box, Button, Stack, Typography } from '@mui/material';
import { BookingFormField, ReminderValue, SessionActionModalType } from 'common-types';
import { memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useAnalytics } from 'services/analytics';
import { invertedButtonColor, resetButtonStyles } from 'styles';

import { DateTime } from '../date-time';
import { ReminderDropdown } from '../reminder-dropdown';

interface EditSessionFormProps {
  userEmail: string;
  isPaidOptionsActive: boolean;
  isEditMeetLoading?: boolean;
  handleCancelSession: () => void;
}

export const EditSessionForm = memo<EditSessionFormProps>(
  ({ userEmail, isPaidOptionsActive, isEditMeetLoading, handleCancelSession }) => {
    const { trackEvent } = useAnalytics();
    const {
      control,
      getValues,
      formState: { dirtyFields, isDirty },
    } = useFormContext();
    const location = useLocation();

    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const reminderValue = getValues(BookingFormField.REMINDER);
    const isReminderDirty = dirtyFields[BookingFormField.REMINDER];

    const handleOpenDropdown = (): void => {
      setIsSelectOpen(true);
    };

    const handleCloseDropdown = (): void => {
      setIsSelectOpen(false);
    };

    const isShowReminderWarningText =
      isReminderDirty && isPaidOptionsActive && reminderValue !== ReminderValue.NO_REMIND;

    useEffect(() => {
      if (isSelectOpen && isPaidOptionsActive) {
        trackEvent('paid_notification', { user_email: userEmail, placement: 'onboarding', once: true });
      }
    }, [isPaidOptionsActive, isSelectOpen, trackEvent, userEmail]);

    return (
      <Box maxWidth={472} py={12} mx="auto">
        <Typography variant="h2" component="p" fontSize={29} textAlign="center" mb={8}>
          Edit date & time
        </Typography>
        <Box mb={{ xs: isShowReminderWarningText ? 14 : 18, lg: isShowReminderWarningText ? 18 : 22 }}>
          <Box mb={4}>
            <DateTime pathname={location.pathname} type={SessionActionModalType.EDIT} />
          </Box>
          <Controller
            name={BookingFormField.REMINDER}
            control={control}
            render={({ field }): JSX.Element => (
              <ReminderDropdown
                field={field}
                isOpen={isSelectOpen}
                onOpen={handleOpenDropdown}
                onClose={handleCloseDropdown}
              />
            )}
          />
        </Box>
        <Stack gap={6}>
          <Button type="submit" disabled={!isDirty || isEditMeetLoading} sx={[invertedButtonColor, { py: 4 }]}>
            confirm
          </Button>
          <Box
            component="button"
            type="button"
            onClick={handleCancelSession}
            disabled={isEditMeetLoading}
            sx={[
              resetButtonStyles,
              {
                fontSize: 17,
                letterSpacing: 0.5,
                color: 'error.main',
                textAlign: 'center',
                transition: 'opacity 0.2s ease',
                '&:hover': { opacity: 0.6 },
              },
            ]}
          >
            Cancel session
          </Box>
        </Stack>
      </Box>
    );
  },
);
