import { ReminderValue } from './user';

export enum SessionStatus {
  COMPLETED = 'COMPLETED',
  BOOKED = 'BOOKED',
  MISSED = 'MISSED',
  NO_SHOW = 'NO_SHOW',
  DROP_OFF = 'DROP_OFF',
  CANCELED = 'CANCELED',
}

export enum ReminderStatus {
  SUCCESS = 'SUCCESS',
  SCHEDULED = 'SCHEDULED',
  ERROR = 'ERROR',
}

export enum BookingFormField {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  DATE = 'date',
  TIME = 'time',
  REMINDER = 'reminder',
}

export enum SessionModalStatus {
  TIME_OUT = 'TIME_OUT',
  REACH_LIMIT = 'REACH_LIMIT',
  SCHEDULED = 'SCHEDULED',
  START_SOON = 'START_SOON',
  ALREADY_STARTED = 'ALREADY_STARTED',
}

export enum SessionActionModalType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export interface IStylist {
  id?: string;
  name?: string;
  email?: string;
  role?: string;
  status?: boolean;
  createdAt?: string;
}

export interface ISession {
  id?: string;
  phone?: string;
  reminderStatus?: ReminderStatus;
  reminderType?: ReminderValue;
  status?: SessionStatus;
  stylistId?: string;
  timestamp?: number;
  userEmail?: string;
  userId?: string;
  userName?: string;
  stylist?: IStylist;
  paidNotification?: boolean;
  sessionType?: string;
}

export interface ISessionResponse {
  count: number;
  data: ISession[];
}

export interface ISessionLimit {
  title?: string;
  limit: number;
  sessionType: string;
  isOneTime?: boolean;
}

export type FirebaseSessionContent = {
  image?: {
    url?: string;
  };
  title: string;
};

export interface IFirebaseSessionContent {
  id: string;
  content: FirebaseSessionContent;
}
