export enum Advantage {
  CLOSET = 'CLOSET',
  CURATED = 'CURATED',
  RECOMMENDATION = 'RECOMMENDATION',
}

export enum Feature {
  TIME = 'TIME',
  EXPERIMENT = 'EXPERIMENT',
  PLAN = 'PLAN',
}

export type TDynamicUpsellProducts = {
  limit: number;
  name: string;
  payment_type: string;
  price: { value: number };
  product_id: string;
  session_type: {
    attachmentType: string;
    color: {
      background_1: string;
      background_2: string;
    };
    session_type: string;
    smsAndWhatsUpReminder: string[];
    title: string;
  };
};

export type TDynamicUpsellContent = {
  active: boolean;
  bubbleText: string;
  button: {
    subText: string;
    text: string;
  };
  image: { url: string };
  legalText: string;
  name: string;
  should_show_shield_icon: boolean;
  text: string;
  title: string;
  products: TDynamicUpsellProducts[];
};

export type TModalDataPrettified = {
  product: TDynamicUpsellProducts;
  texts: string[];
  buttonSubtext: string;
  price: number;
  priceBefore: string;
  imageUrl: string;
  productId: string;
  title: string;
  buttonTitle: string;
  sessionType: string;
  sessionLimit: number;
  isOneTime: boolean;
  isActive: boolean;
  amount: number;
  isDataReady: boolean;
};
