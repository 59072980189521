import { Box } from '@mui/material';
import { HeaderBooking } from 'components/header-booking';
import { SessionWatcher } from 'components/session-watcher';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const LayoutBooking: FC = () => {
  return (
    <>
      <HeaderBooking />
      <Box component="main" pt={{ xs: 11, lg: 16 }}>
        <Outlet />
      </Box>
      <SessionWatcher />
    </>
  );
};
