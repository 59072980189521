import { Box, Drawer, Grid, Skeleton, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { Fonts, RoutePaths } from 'common-types';
import { useGetUserData } from 'hooks/use-get-user-data';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useStore } from 'store';
import { shallow } from 'zustand/shallow';

import DefaultAvatar from './assets/default-avatar.png';
import { ReactComponent as EnlargeIcon } from './assets/enlarge-icon.svg';
import { ReactComponent as EnlargeIconSecond } from './assets/enlarge-icon-2.svg';
import { LinksList } from './components/links-list';
import { PremiumBadge } from './components/premium-badge';
import { avatarStyles, drawerStyles, profileBlockStyles } from './styles';

export const DrawerMenu = memo(() => {
  const profileRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const {
    isSuccess: isUserGetUserDataSuccess,
    userName,
    userEmail,
    userPhoto,
    isSubscriptionExpired,
  } = useGetUserData();

  const { isDrawerDialogOpen, photo, setIsDrawerDialogOpen, setProfileRef } = useStore(
    (state) => ({
      isDrawerDialogOpen: state.isDrawerDialogOpen,
      photo: state.photo,
      setIsDrawerDialogOpen: state.setIsDrawerDialogOpen,
      setProfileRef: state.setProfileRef,
    }),
    shallow,
  );

  const handleOpenDialog = (): void => {
    setIsDrawerDialogOpen(true);
  };

  const handleRenewSubscriptionClick = useCallback(async (): Promise<void> => {
    navigate(RoutePaths.RENEW_SUBSCRIPTION);
  }, [navigate]);

  useEffect(() => {
    if (profileRef?.current) {
      setProfileRef(profileRef.current);
    }
  }, [profileRef?.current]);

  return (
    <Drawer variant="permanent" sx={drawerStyles}>
      <Grid container flexWrap="nowrap" direction="column" component="aside" height="100%">
        <Grid item container justifyContent="center" p={8}>
          <Logo />
        </Grid>
        <Grid item>
          <LinksList />
        </Grid>
        {location.pathname !== RoutePaths.RENEW_SUBSCRIPTION && (
          <Grid item px={6} my="auto" py={4}>
            <>{isSubscriptionExpired && <PremiumBadge handleClick={handleRenewSubscriptionClick} />}</>
          </Grid>
        )}
        <Grid
          mt="auto"
          position="relative"
          ref={profileRef}
          item
          p={4}
          sx={[
            profileBlockStyles,
            {
              backgroundColor: isDrawerDialogOpen ? 'primary.light' : 'common.white',
            },
          ]}
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            width="100%"
            component="button"
            sx={{ cursor: 'pointer', opacity: 0 }}
            onClick={handleOpenDialog}
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  {isUserGetUserDataSuccess ? (
                    <Box component="img" src={photo || userPhoto || DefaultAvatar} alt="avatar" sx={avatarStyles} />
                  ) : (
                    <Skeleton variant="circular" width={36} height={36} />
                  )}
                </Grid>
                <Grid item ml={1.5}>
                  {isUserGetUserDataSuccess ? (
                    <Typography
                      variant="body1"
                      noWrap
                      width={90}
                      fontFamily={`${Fonts.TITLE}, sans-serif`}
                      fontWeight={700}
                    >
                      {userName || 'Dear'}
                    </Typography>
                  ) : (
                    <Skeleton variant="text" width={100} height={18} />
                  )}
                  {isUserGetUserDataSuccess ? (
                    <Typography
                      variant="body2"
                      fontFamily={`${Fonts.TITLE}, sans-serif`}
                      width={90}
                      noWrap
                      color="custom.textGrey"
                    >
                      {userEmail}
                    </Typography>
                  ) : (
                    <Skeleton variant="rounded" width={90} height={10} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item position="relative" width={16} height={16} sx={{ pointerEvents: 'none' }}>
              <Box position="absolute">{isDrawerDialogOpen ? <EnlargeIconSecond /> : <EnlargeIcon />}</Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
});
