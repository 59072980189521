import { ReminderStatus, SessionStatus } from './sessions';
import { ReminderValue } from './user';

export enum LOADING_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum HttpCodeSessionException {
  SESSION_IS_STARTED = 2003,
  SESSION_LIMIT = 2002,
}

export enum HttpCodeUserException {
  USER_NOT_FOUND = 1001,
}

export type TSessionMetadata = {
  created_by?: 'user' | 'stylist';
  platform_created?: 'lumi_web';
  platform_started?: 'lumi_web';
  platform_completed?: 'lumi_web';
};

export enum EStrapiApi {
  ARTICLE = '/articles',
  HIGHLIGHTS = '/highlights',
  STYLE_ARTICLE = '/style-articles',
  LEGAL_SENTENCE = '/legal-sentences',
}

export interface ICreateSessionData {
  email: string;
  timestamp: number;
  phone?: string;
  userName?: string;
  userId?: string;
  reminderType: ReminderValue;
  secondsFromUtc: number;
  sessionType?: string;
  metadata: TSessionMetadata;
}

export interface IEditSessionData {
  stylistId?: string;
  timestamp?: number;
  reminderType?: ReminderValue;
  reminderStatus?: ReminderStatus;
  status?: SessionStatus;
  secondsFromUtc?: number;
  metadata?: TSessionMetadata;
  endTimestamp?: number;
}
