import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { RoutePaths } from 'common-types';
import { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocalStorageStore } from 'store';

import { routeMap } from './utils/data';

export const TutorialModalCard = memo(() => {
  const location = useLocation();
  const path = location?.pathname;

  const {
    isUserStylePlanOnboardingDone,
    isUserStylistOnboardingDone,
    isUserGuidesOnboardingDone,
    setIsUserStylePlanOnboardingDone,
    setIsUserStylistOnboardingDone,
    setIsUserGuidesOnboardingDone,
  } = useLocalStorageStore((state) => ({
    isUserStylePlanOnboardingDone: state.isUserStylePlanOnboardingDone,
    isUserStylistOnboardingDone: state.isUserStylistOnboardingDone,
    isUserGuidesOnboardingDone: state.isUserGuidesOnboardingDone,
    setIsUserStylePlanOnboardingDone: state.setIsUserStylePlanOnboardingDone,
    setIsUserStylistOnboardingDone: state.setIsUserStylistOnboardingDone,
    setIsUserGuidesOnboardingDone: state.setIsUserGuidesOnboardingDone,
  }));

  const modalDoneMap: Partial<Record<RoutePaths, boolean>> = {
    [RoutePaths.STYLE_PLAN]: isUserStylePlanOnboardingDone,
    [RoutePaths.STYLIST]: isUserStylistOnboardingDone,
    [RoutePaths.GUIDES]: isUserGuidesOnboardingDone,
  };

  const isModalOpen = Object.keys(routeMap).includes(path) && !modalDoneMap[path as RoutePaths];

  const handleClose = useCallback(() => {
    switch (path) {
      case RoutePaths.STYLE_PLAN:
        setIsUserStylePlanOnboardingDone(true);
        break;
      case RoutePaths.STYLIST:
        setIsUserStylistOnboardingDone(true);
        break;
      case RoutePaths.GUIDES:
        setIsUserGuidesOnboardingDone(true);
        break;
      default:
        break;
    }
  }, [path]);

  return (
    <Modal open={isModalOpen}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 350,
          bgcolor: 'common.white',
          borderRadius: 3,
          overflow: 'hidden',
          outline: 'none',
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Box
              component="img"
              src={routeMap[path as RoutePaths]?.src}
              alt={routeMap[path as RoutePaths]?.alt}
              draggable={false}
              width="100%"
              height={258}
              sx={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Grid>
          <Grid item pt={6} pb={8} px={8}>
            <Grid container direction="column">
              <Grid item mb={8} px={2}>
                <Typography variant="h4" component="p" textAlign="center" fontWeight={500}>
                  {routeMap[path as RoutePaths]?.title}
                </Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Button
                  fullWidth
                  onClick={handleClose}
                  sx={{
                    maxWidth: 159,
                    height: 45,
                    bgcolor: 'primary.main',
                    color: 'common.white',
                    '&:hover': {
                      bgcolor: 'custom.buttonColor',
                      color: 'primary.main',
                    },
                    '&.Mui-focusVisible': {
                      bgcolor: 'custom.buttonColor',
                      color: 'primary.main',
                    },
                  }}
                >
                  Got it
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
});
