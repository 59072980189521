export enum QuizAnswer {
  NAME = 'name',
  BODY = 'body',
  HEIGHT = 'height',
  WEIGHT = 'weight',
  GOAL = 'goal',
  EYES = 'eyes',
  HAIR = 'hair',
  SKIN = 'skin',
  COLOR_PALETTE = 'color_palette',
  CLOTHING_STYLE = 'style_type',
  SKIN_TONE = 'skin_tone',
  FAVORITE_COLOR = 'favorite_color',
  BODY_TYPE = 'body_type',
  STYLE_TYPE = 'style_type',
  GOAL_SETTING = 'goal_setting',
  STYLE_ELEVATION_TARGET = 'style_elevation_target',
  BUST = 'bust',
  WAIST = 'waist',
  HIPS = 'hips',
  HIGH_HIPS = 'high_hips',
  PREFERRED_OUTFIT = 'preferred_outfit',
}

export enum QuizBodyShape {
  APPLE = 'apple',
  PEAR = 'pear',
  HOURGLASS = 'hourglass',
  COLUMN = 'column',
  INVERTED_TRIANGLE = 'triangle',
}

export enum QuizGoal {
  CONVENIENCE = 'convenience',
  BODY_SHAPE_CORRECTION = 'body_shape_correction',
  SELF_CONFIDENCE = 'self_confidence',
  EMPHASIZE_MY_PERSONALITY = 'emphasize_my_personality',
  CAREER_ADVANCEMENT = 'career_advancement',
  FIND_A_LIFE_PARTNER = 'find_partner',
}

export enum QuizEyeColor {
  BLACK = 'black',
  BROWN = 'brown',
  LIGHT_BROWN = 'light_brown',
  GRAY_BROWN = 'gray_brown',
  GREEN = 'green',
  HAZEL = 'hazel',
  BLUE = 'blue',
  GRAY_BLUE = 'gray_blue',
  GRAY = 'gray',
  DARK_GRAY = 'dark_gray',
}

export enum QuizHairColor {
  BLACK = 'black',
  BROWN = 'brown',
  LIGHT_BROWN = 'light_brown',
  BLOND = 'blond',
  GINGER = 'ginger',
  OTHER = 'other',
}

export enum QuizSkinColor {
  FAIR = 'fair',
  SIENNA = 'sienna',
  PALE_IVORY = 'pale_ivory',
  BAND = 'band',
  IVORY = 'ivory',
  UMBER = 'umber',
  SAND = 'sand',
  ESPRESSO = 'espresso',
  PORCELAIN = 'porcelain',
  SKIP = 'skip',
}

export enum QuizFavoriteColor {
  PINK = 'pink',
  RED = 'red',
  TEAL = 'teal',
  PURPLE = 'purple',
  LIGHT_BLUE = 'light_blue',
  INDIGO = 'indigo',
  YELLOW = 'yellow',
  GREEN = 'green',
  WHITE = 'white',
  BLACK = 'black',
  BEIGE = 'beige',
  ORANGE = 'orange',
}

export enum QuizSkinTone {
  WARM_SPRING = 'warm_spring',
  SOFT_AUTUMN = 'soft_autumn',
  CLEAR_SPRING = 'clear_spring',
  WARM_AUTUMN = 'warm_autumn',
  LIGHT_SPRING = 'light_spring',
  DEEP_AUTUMN = 'deep_autumn',
  COOL_SUMMER = 'cool_summer',
  DEEP_WINTER = 'deep_winter',
  SOFT_SUMMER = 'soft_summer',
  CLEAR_WINTER = 'clear_winter',
  LIGHT_SUMMER = 'light_summer',
  COOL_WINTER = 'cool_winter',
}

export enum QuizClothingStyle {
  CASUAL = 'casual',
  PREPPY = 'preppy',
  URBAN = 'urban',
  BOHO = 'boho',
  LADYLIKE = 'ladylike',
  CREATIVE = 'creative',
  SPORT = 'sport',
  FUNKY_ROCK = 'funky_rock',
}

export enum QuizStyleElevationTarget {
  SHOW_ME_WHAT_TO_WEAR = 'show_me_what_to_wear',
  HELP_ME_DECIDE = 'help_me_decide',
  MIX_AND_MATCH_ITEMS = 'mix_and_match_items',
  CREATE_NEW_LOOKS = 'create_new_looks',
  OTHER = 'other',
}

export enum QuizPreferredOutfit {
  DAILY = 'daily',
  PROFESSIONAL = 'professional',
  SEXY = 'sexy',
  VACATION = 'vacation',
  GYM = 'gym',
  DATE = 'date',
  BUSINESS_CASUAL = 'business_casual',
  OTHER = 'other',
}

export enum QuizHeight {
  FT = 'ft',
  CM = 'cm',
  IN = 'in',
}

export enum QuizWeight {
  KG = 'kg',
  LBS = 'lbs',
}

export enum HeightFormField {
  INCH_FIELD = 'inch',
  CM_FIELD = 'cm',
}

export interface IModalQuiz {
  value?: string | string[];
  onSubmit: (value: string | string[]) => void;
}
export type QuiBaseTypeFields =
  | QuizAnswer.GOAL_SETTING
  | QuizAnswer.BODY_TYPE
  | QuizAnswer.SKIN_TONE
  | QuizAnswer.FAVORITE_COLOR
  | QuizAnswer.CLOTHING_STYLE;
