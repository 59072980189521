export const paginationStyles = {
  '.swiper-pagination': {
    bottom: '-48px !important',
    '&-bullet': {
      width: 8,
      height: 8,
      backgroundColor: '#E0DBF2',
      '&-active': {
        backgroundColor: '#8777D9',
      },
    },
  },
};
