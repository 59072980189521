import { RoutePaths, UserPermissionStatus } from 'common-types';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useLocalStorageStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useGetUserData } from '../../hooks/use-get-user-data';

interface PrivateRouteProps {
  isOnlyForSubscribed?: boolean;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ isOnlyForSubscribed }) => {
  const location = useLocation();
  const { isSubscriptionExpired } = useGetUserData();

  const { userPermission } = useLocalStorageStore(
    (state) => ({
      userPermission: state.userPermission,
    }),
    shallow,
  );

  if (isOnlyForSubscribed && userPermission === UserPermissionStatus.LOGGED_IN && !isSubscriptionExpired) {
    return <Outlet />;
  }

  if (isOnlyForSubscribed && userPermission === UserPermissionStatus.LOGGED_IN && isSubscriptionExpired) {
    return <Navigate to={RoutePaths.RENEW_SUBSCRIPTION} state={{ from: location }} replace />;
  }

  if (userPermission === UserPermissionStatus.LOGGED_IN) {
    return <Outlet />;
  }

  if (userPermission === UserPermissionStatus.LOGGED_OUT) {
    return <Navigate to={RoutePaths.SIGN_IN} state={{ from: location }} replace />;
  }

  return <Navigate to="/404" state={{ from: location }} replace />;
};
