import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { IProgressParts } from 'common-types';
import { useGetUserData } from 'hooks/use-get-user-data';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'services/analytics';
import { useStore } from 'store';

export const Recent = memo(() => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { isSuccess: isUserDataLoaded, userRecent } = useGetUserData();

  const { setPartView } = useStore((state) => ({
    setPartView: state.setPartView,
  }));

  const handleRecentClick = (item: IProgressParts): void => {
    trackEvent('recent_tap');

    const id = item?.id?.slice(0, item?.id?.indexOf('.'));
    const part = item?.id?.slice(item?.id?.indexOf('.') + 1);

    setPartView(+part);
    navigate(`/style-plan/${id}`);
  };

  if (!userRecent) {
    return null;
  }

  return (
    <Box borderRadius={{ lg: 2.5 }} minHeight={325} bgcolor="common.white" p={{ xs: 4, lg: 6 }}>
      {isUserDataLoaded ? (
        <>
          <Typography variant="h2" fontSize={16} mb={6}>
            Recent
          </Typography>
          {userRecent?.length === 0 && (
            <Typography variant="body1" color="secondary.main">
              Visit your Style Plan and Guides to save recent pages here
            </Typography>
          )}
          {userRecent?.map((item) => (
            <Grid key={item?.topic} container mb={4} onClick={(): void => handleRecentClick(item)}>
              <Grid item>
                <Box
                  component="img"
                  src={item?.screens[0]?.view[0]?.image?.url}
                  alt={item?.topic}
                  width={62}
                  height={62}
                  borderRadius={3}
                  sx={{ objectFit: 'cover' }}
                />
              </Grid>
              <Grid item container direction="column" xs minHeight={62}>
                <Grid container height="100%" alignItems="center" pl={2.5}>
                  <Grid item xs>
                    <Typography variant="h4" component="p" fontSize={14} mb={1}>
                      {item?.topic}
                    </Typography>
                    <Typography variant="body2" fontSize={12} color="custom.textGrey">
                      {item?.screens[0]?.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ width: 'calc(100% - 72px)', ml: 'auto' }} />
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Skeleton variant="rounded" height={277} />
      )}
    </Box>
  );
});
