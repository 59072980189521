import { Fonts } from 'common-types';
import theme from 'providers/theme/theme';

export const notesContainerStyles = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': { width: 4, height: 116, WebkitAppearance: 'none' },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 50,
    border: '2px solid',
    borderColor: '#ECECEC',
    backgroundColor: 'rgba(0 0 0 / 0.5)',
  },
};

export const saveButtonStyles = {
  maxWidth: 87,
  height: 28,
  bgcolor: 'primary.main',
  color: 'common.white',
  '&:hover, &:focus-visible': {
    bgcolor: 'primary.dark',
  },
};

export const titleStyles = {
  mb: 4,
  color: 'custom.textGreyDark',
  '& input': {
    fontFamily: `${Fonts.TITLE}, sans-serif`,
    fontSize: 16,
    fontWeight: 700,
    py: 0,
    color: 'transparent',
    caretColor: theme.palette.custom.textGreyDark,
  },
  '& .MuiInputBase-root': {
    color: 'custom.textGreyDark',
  },
  '& .MuiInputBase-root::after': { display: 'none' },
  '& .MuiInputBase-root::before': { display: 'none' },
};
