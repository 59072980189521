import { useGrowthBook } from '@growthbook/growthbook-react';
import { useGetUserData } from 'hooks/use-get-user-data';
import { useEffect } from 'react';

const useInitGrowthbook = (): void => {
  const { userId } = useGetUserData();
  const growthbook = useGrowthBook();

  useEffect(() => {
    if (userId) {
      growthbook.init({ streaming: true });

      growthbook.setAttributes({
        id: userId,
        url: window.location.origin,
        userAgent: navigator.userAgent,
      });
    }
  }, [growthbook, userId]);
};

export default useInitGrowthbook;
