import { StateCreator } from 'zustand';

import { IPaymentsStore } from './types';

export const paymentsSlice: StateCreator<IPaymentsStore, [], [], IPaymentsStore> = (set) => ({
  isPaypalButtonLoading: false,
  isPaymentFormOpen: false,
  product: null,
  setIsPaypalButtonLoading: (isPaypalButtonLoading) => set({ isPaypalButtonLoading }),
  setIsPaymentFormOpen: (isPaymentFormOpen) => set({ isPaymentFormOpen }),
  setProduct: (product) => set({ product }),
});
