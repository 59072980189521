import { Box, Modal } from '@mui/material';
import { ReviewStep } from 'common-types';
import { memo, useEffect } from 'react';
import { useAnalytics } from 'services/analytics';
import { useLocalStorageStore, useStore } from 'store';
import { shallow } from 'zustand/shallow';

import { FeedbackForm } from './components/feedback-form';
import { FeedbackSuccess } from './components/feedback-success';
import { Rating } from './components/rating';
import { Trustpilot } from './components/trustpilot';

export const Review = memo(() => {
  const { trackEvent } = useAnalytics();

  const { reviewModal } = useStore(
    (state) => ({
      reviewModal: state.reviewModal,
    }),
    shallow,
  );

  const { isReviewModalShown } = useLocalStorageStore(
    (state) => ({
      isReviewModalShown: state.isReviewModalShown,
    }),
    shallow,
  );

  useEffect(() => {
    if (reviewModal?.isOpen) {
      trackEvent('review_popup_shown');
    }
  }, [reviewModal?.isOpen, trackEvent]);

  return (
    <Modal open={reviewModal.isOpen && !isReviewModalShown}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="calc(100% - 32px)"
        maxWidth={749}
        borderRadius={4}
        bgcolor="common.white"
        sx={{ transform: 'translate(-50%, -50%)', outline: 'none' }}
      >
        {reviewModal.step === ReviewStep.RATING && <Rating />}
        {reviewModal.step === ReviewStep.FEEDBACK && <FeedbackForm />}
        {reviewModal.step === ReviewStep.FEEDBACK_SUCCESS && <FeedbackSuccess />}
        {reviewModal.step === ReviewStep.TRUSTPILOT && <Trustpilot />}
      </Box>
    </Modal>
  );
});
