import theme from 'providers/theme/theme';

export const drawerStyles = {
  flexShrink: 0,
  width: 210,

  '& .MuiDrawer-paper': {
    borderRight: '1px solid rgba(229, 229, 234, 0.70)',
  },
};

export const navLinkStyles = {
  display: 'flex',
  px: 6,
  py: 3,
  alignItems: 'center',
  textDecoration: 'none',
  transition: 'background-color 0.2s ease',
  '&:hover, &:focus-visible': {
    backgroundColor: 'primary.light',
  },
};

export const avatarStyles = {
  width: 36,
  height: 36,
  borderRadius: '50%',
  objectFit: 'cover',
};

export const profileBlockStyles = {
  cursor: 'pointer',
  borderTop: `1px solid ${theme.palette.custom.lightGrey}`,
  '&:hover, &:focus-visible': {
    backgroundColor: 'primary.light',
  },
};

export const listItemStyles = {
  position: 'relative',
  pl: 3,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 4,
    left: 2,
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: 'primary.main',
  },
};

export const customButtonStyles = {
  position: 'relative',
  display: 'block',
  width: '100%',
  textAlign: 'center',
  maxWidth: 94,
  borderRadius: 1.5,
  bgcolor: 'custom.orange',
  cursor: 'pointer',
  border: 'none',
  transition: 'opacity 0.2s ease',
  overflow: 'hidden',
  '@keyframes shift': {
    '0%': {
      transform: 'translateX(-20px) rotate(15deg)',
      visibility: 'hidden',
    },
    '1%': {
      transform: 'translateX(-20px) rotate(15deg)',
      visibility: 'visible',
    },
    '98%': {
      transform: 'translateX(110px) rotate(15deg)',
      visibility: 'visible',
    },
    '99%': {
      transform: 'translateX(100px) rotate(15deg)',
      visibility: 'hidden',
    },
    '100%': {
      transform: 'translateX(-30px) rotate(15deg)',
      visibility: 'hidden',
    },
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -8,
    left: 0,
    width: '100%',
    maxWidth: 10,
    height: '150%',
    bgcolor: 'common.white',
    filter: 'blur(6px)',
    transform: 'rotate(25deg)',
    animation: 'shift 2s infinite',
  },

  '&:hover, &:focus-visible': {
    opacity: 0.8,
  },
};

export const chatButtonStyles = {
  backgroundColor: 'common.white',
  color: 'primary.main',
  border: '1px solid',
  borderColor: 'primary.main',
  '&:hover': {
    bgcolor: 'custom.buttonColor',
    color: 'primary.main',
  },
  '&.Mui-focusVisible': {
    bgcolor: 'custom.buttonColor',
    color: 'primary.main',
  },
};
