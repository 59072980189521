import { StateCreator } from 'zustand';

import { TDynamicUpsellStore } from './types';

export const dynamicUpsellsSlice: StateCreator<TDynamicUpsellStore, [], [], TDynamicUpsellStore> = (set) => ({
  dynamicUpsellContent: null,
  isOpenModal: false,
  updateDynamicUpsellContent: (content): void => {
    set({ dynamicUpsellContent: content });
  },
  openModal: (): void => {
    set({ isOpenModal: true });
  },
  closeModal: (): void => {
    set({ isOpenModal: false });
  },
});
