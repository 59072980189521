import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { FC } from 'react';

import theme from './theme';

const globalStyles = {
  body: {
    backgroundColor: '#fff',
    fontSize: 16,
    letterSpacing: 0,
  },
  a: {
    fontFamily: 'inherit',
    color: 'inherit',
    textDecoration: 'none',
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
};

export const MuiThemeProvider: FC<{ children: JSX.Element }> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles styles={globalStyles} />
    {children}
  </ThemeProvider>
);
