export enum PaymentMethod {
  PAYPAL = 'PAYPAL',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  DEFAULT = 'default',
  NONE = 'none',
}

export interface OrderPaymentsData {
  name: 'solid_card' | 'solid_gate';
  url?: string;
  signature?: string;
  paymentIntent?: string;
  merchantId?: string;
}

export interface PaymentResponse {
  orderId: string;
  paymentResponse: OrderPaymentsData[];
}

export interface IMerchantData {
  merchant: string;
  signature: string;
  paymentIntent: string;
}
