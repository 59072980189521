import { setUserId as amplitudeSetUserId } from '@amplitude/analytics-browser';
import { useQuery } from '@tanstack/react-query';
import { QueryKey, RoutePaths, UserPermissionStatus, UserSource } from 'common-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useLocalStorageStore, useStore } from 'store';
import { shallow } from 'zustand/shallow';

import { IUseInitSession } from './utils/types';

export const useInitSession = (): IUseInitSession => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isSessionInitialized,
    setIsSessionInitialized,
    setSubscriptionUserSource,
    setRestoredStyleSession,
    setIsOpenPaymentModal,
  } = useStore(
    (state) => ({
      isSessionInitialized: state.isSessionInitialized,
      setIsSessionInitialized: state.setIsSessionInitialized,
      setSubscriptionUserSource: state.setSubscriptionUserSource,
      setRestoredStyleSession: state.setRestoredStyleSession,
      setIsOpenPaymentModal: state.setIsOpenPaymentModal,
    }),
    shallow,
  );

  const { setToken, setIsUserLoggedIn, setUserPermission, userId, setUserId, userFullOnboardingDone } =
    useLocalStorageStore(
      (state) => ({
        setToken: state.setToken,
        setIsUserLoggedIn: state.setIsUserLoggedIn,
        setUserPermission: state.setUserPermission,
        userId: state.userId,
        setUserId: state.setUserId,
        userFullOnboardingDone: state.userFullOnboardingDone,
      }),
      shallow,
    );

  const initSession = async (): Promise<{ isUserLoggedIn: boolean }> => {
    try {
      const query = new URLSearchParams(window.location.search);
      const restoredOrderId = query.get('orderId');
      const restoredOrderIdFromLocation = searchParams.get('orderid');
      const restoredDevice = searchParams.get('device');
      const restoredStyleSession = searchParams.get('style');
      const openPaymentWindow = searchParams.get('openpayment');

      if (restoredStyleSession && openPaymentWindow) {
        setRestoredStyleSession(restoredStyleSession);
        setIsOpenPaymentModal(true);
      }

      if (restoredStyleSession) {
        setRestoredStyleSession(restoredStyleSession);
      }

      if (restoredOrderId || restoredOrderIdFromLocation) {
        const token = await api.restoreSession(restoredOrderId || restoredOrderIdFromLocation);
        setUserId(restoredOrderId || restoredOrderIdFromLocation);
        amplitudeSetUserId(restoredOrderId || restoredOrderIdFromLocation);
        setToken(token);
        navigate(location.pathname || RoutePaths.HOME);
        setIsSessionInitialized(true);
        setIsUserLoggedIn(true);
        setUserPermission(UserPermissionStatus.LOGGED_IN);

        if (restoredDevice) {
          setSubscriptionUserSource(UserSource.IOS);
          userFullOnboardingDone();
        }

        return { isUserLoggedIn: true };
      }

      if (userId) {
        await api.restoreSession(userId);
        setIsSessionInitialized(true);
        setIsUserLoggedIn(true);
        setUserPermission(UserPermissionStatus.LOGGED_IN);
        return { isUserLoggedIn: true };
      }

      return { isUserLoggedIn: false };
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  };

  const { data } = useQuery([QueryKey.INIT_SESSION], () => initSession(), {
    enabled: !isSessionInitialized,
  });

  return { isSessionInitialized, isUserLoggedIn: data?.isUserLoggedIn };
};
