import { ReactComponent as ProfileIcon } from 'assets/icons/profile-icon.svg';
import { ReactComponent as SignOutIcon } from 'assets/icons/sign-out-icon.svg';
import { RoutePaths } from 'common-types';
import { SUPPORT_EMAIL } from 'utils';

import { ReactComponent as SupportIcon } from '../assets/support-icon.svg';
import { IProfileDialogData } from './types';

export const profileDialogData: IProfileDialogData[] = [
  {
    title: 'Profile',
    href: RoutePaths.PROFILE,
    isRedirected: true,
    Icon: ProfileIcon,
  },
  {
    title: 'Support',
    href: `mailto:${SUPPORT_EMAIL}`,
    isNativeLink: true,
    Icon: SupportIcon,
  },
  {
    title: 'Log out',
    href: RoutePaths.SIGN_OUT,
    Icon: SignOutIcon,
  },
];
