import { Box, Button, Link, Typography } from '@mui/material';
import { Component, ErrorInfo, GetDerivedStateFromError, ReactNode } from 'react';

import { SUPPORT_EMAIL } from '../../utils';
import type { ErrorBoundaryProps, ErrorBoundaryState } from './types';

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError: GetDerivedStateFromError<ErrorBoundaryProps, ErrorBoundaryState> = () => ({
    hasError: true,
  });

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height={1} gap={8}>
          <Typography variant="h1" textAlign="center">
            Something went wrong.
            <br /> Please refresh the page.
          </Typography>
          <Button onClick={(): void => window.location.reload()}>Refresh</Button>
          <Typography variant="body1" textAlign="center">
            If the problem persists, please contact us at <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}
