import { StateCreator } from 'zustand';

import { ISessionStore } from './types';

export const sessionsSlice: StateCreator<ISessionStore, [], [], ISessionStore> = (set) => ({
  isConfirmModalOpen: false,
  sessionModal: {
    isOpen: false,
    type: null,
  },
  sessionActionModal: {
    isOpen: false,
    type: null,
  },
  setIsConfirmModalOpen: (isConfirmModalOpen: boolean): void => set(() => ({ isConfirmModalOpen })),
  updateSessionModal: (data): void => {
    set((state) => ({
      sessionModal: {
        ...state.sessionModal,
        ...data,
      },
    }));
  },
  closeSessionModal: (): void => {
    set(() => ({
      sessionModal: {
        isOpen: false,
        type: null,
      },
    }));
  },
  updateSessionActionModal: (data): void => {
    set((state) => ({
      sessionActionModal: {
        ...state.sessionActionModal,
        ...data,
      },
    }));
  },
  closeSessionActionModal: (): void => {
    set(() => ({
      sessionActionModal: {
        isOpen: false,
        type: null,
      },
    }));
  },
});
