import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { IUser, QueryKey } from 'common-types';
import api from 'services/api';
import { useLocalStorageStore } from 'store';
import { shallow } from 'zustand/shallow';

export const useSetUserData = (
  options?: UseMutationOptions<unknown, unknown, IUser>,
): UseMutationResult<unknown, unknown, IUser, unknown> => {
  const { userId } = useLocalStorageStore((state) => ({ userId: state.userId }), shallow);

  return useMutation([QueryKey.USER_DATA, userId], (userData: IUser) => api.setUserInfo({ userData }, userId), options);
};
