import { Box, Stack, Typography } from '@mui/material';
import { customButtonStyles, listItemStyles } from 'components/drawer/styles';
import { premiumFeatures } from 'components/drawer/utils/data';
import { memo } from 'react';

import { ReactComponent as CrownIcon } from '../../assets/crown.svg';
import { IPremiumBadge } from '../../utils/types';

export const PremiumBadge = memo<IPremiumBadge>(({ handleClick }) => (
  <Box
    p={4}
    borderRadius={2.5}
    sx={{ background: 'linear-gradient(151.31deg, #F9F8FD 0%, #D7CDFF 48.44%, #F9F8FD 100%)' }}
  >
    <Stack alignItems="center" gap={2}>
      <CrownIcon />
      <Box display="flex" justifyContent="center" width="100%" mb={2}>
        <Box component="button" type="button" sx={customButtonStyles} onClick={handleClick}>
          <Typography position="relative" variant="body1" fontWeight={500} color="common.white" py={1.5} zIndex={1}>
            Premium
          </Typography>
        </Box>
      </Box>
      <Typography variant="h4" fontSize={14} fontWeight={500} textAlign="center">
        Get your personal style improvement
      </Typography>
      <Stack component="ul" gap={2}>
        {premiumFeatures.map((feature) => (
          <Typography key={feature} variant="body2" lineHeight={1.2} sx={listItemStyles}>
            {feature}
          </Typography>
        ))}
      </Stack>
    </Stack>
  </Box>
));
