import { useQuery } from '@tanstack/react-query';
import { ISession, QueryKey, ReminderValue, SessionStatus } from 'common-types';
import { fromUnixTime, isFuture, isPast, isToday } from 'date-fns';
import { useGetUserData } from 'hooks/use-get-user-data';
import chicApi from 'services/chic-api';

interface ILastUserSession {
  isLastUserSessionSuccess: boolean;
  isLastUserSessionLoading: boolean;
  isLastUserSessionError: boolean;
  lastUserSession: ISession;
  lastUserSessionTimestamp: number;
  lastUserSessionReminder: ReminderValue;
  lastUserSessionStatus: SessionStatus;
  lastUserSessionId: string;
  isNextSessionInPast: boolean;
  isNextSessionInFuture: boolean;
  isSessionStartsToday: boolean;
  lastUserSessionPhone: string;
  lastUserSessionPaidNotification: boolean;
}

export const useGetLastUserSession = (): ILastUserSession => {
  const { userId } = useGetUserData();

  const { isSuccess, isError, isLoading, data } = useQuery(
    [QueryKey.LAST_USER_SESSION, userId],
    () => chicApi.getLastUserSession(userId),
    {
      enabled: !!userId,
    },
  );

  const parsedDate = data?.timestamp ? fromUnixTime(data?.timestamp) : null;

  return {
    isLastUserSessionSuccess: isSuccess,
    isLastUserSessionLoading: isLoading,
    isLastUserSessionError: isError,
    lastUserSession: data,
    lastUserSessionTimestamp: data?.timestamp,
    lastUserSessionReminder: data?.reminderType,
    lastUserSessionStatus: data?.status,
    lastUserSessionPhone: data?.phone,
    lastUserSessionId: data?.id,
    isNextSessionInPast: isPast(parsedDate),
    isNextSessionInFuture: data?.status === SessionStatus.CANCELED ? null : isFuture(parsedDate),
    isSessionStartsToday: data?.status === SessionStatus.CANCELED ? null : isToday(parsedDate),
    lastUserSessionPaidNotification: data?.paidNotification,
  };
};
