import { SessionModalStatus } from 'common-types';

import Booking from '../assets/booking.jpg';
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import ChatImg from '../assets/chat.jpg';
import { ReactComponent as NotificationIcon } from '../assets/notification.svg';
import { ReactComponent as TimeoutIcon } from '../assets/timeout.svg';
import { StylistAction } from './types';

type ContentType = Record<StylistAction, string>;

export const stylistTitleMap: ContentType = {
  [StylistAction.CHAT]: 'Chat with my Stylist',
  [StylistAction.BOOKING]: 'My Sessions',
};

export const stylistSubtitleMap: ContentType = {
  [StylistAction.CHAT]: 'get PERSONAL advice',
  [StylistAction.BOOKING]: 'schedule manager',
};

export const stylistDescriptionMap: Record<StylistAction, JSX.Element | string> = {
  [StylistAction.CHAT]: (
    <span>
      Get 24/7 advice from trusted fashion experts to help discover the real You.
      <br />
      <br />
      Our experts will help you to improve your outfit, to match colors and fashions and to find any fashion item in
      stores.
    </span>
  ),
  [StylistAction.BOOKING]: 'Book a session with a stylist to create your desired outfit from scratch',
};

export const stylistImageMap: ContentType = {
  [StylistAction.CHAT]: ChatImg,
  [StylistAction.BOOKING]: Booking,
};

export const stylistButtonMap: ContentType = {
  [StylistAction.CHAT]: 'ASK a stylist',
  [StylistAction.BOOKING]: 'start',
};

export const sessionStatusTitleMap: Record<SessionModalStatus, string> = {
  [SessionModalStatus.TIME_OUT]: 'Session was timed out',
  [SessionModalStatus.REACH_LIMIT]: 'Sessions limit is reached',
  [SessionModalStatus.SCHEDULED]: 'Almost there!',
  [SessionModalStatus.START_SOON]: 'Session starts soon',
  [SessionModalStatus.ALREADY_STARTED]: 'Opps',
};

export const sessionStatusSubtitleMap: Record<SessionModalStatus, JSX.Element | string> = {
  [SessionModalStatus.TIME_OUT]: 'Please select a different date and time for the session',
  [SessionModalStatus.REACH_LIMIT]: (
    <>
      You have <strong>1 session</strong> with a stylist <strong>per month</strong>. Meanwhile, your stylist is
      available 24/7 for any style-related question.
    </>
  ),
  [SessionModalStatus.SCHEDULED]:
    'Your session is scheduled for another day. If you want to start your session today, please update your session time.',
  [SessionModalStatus.START_SOON]: "It's time to join the session and create your individual outfit",
  [SessionModalStatus.ALREADY_STARTED]: 'You cannot cancel the session because the session has already started',
};

export const sessionStatusButtonMap: Record<SessionModalStatus, string> = {
  [SessionModalStatus.TIME_OUT]: 'Book a new session',
  [SessionModalStatus.REACH_LIMIT]: 'Choose another date',
  [SessionModalStatus.SCHEDULED]: 'Ok',
  [SessionModalStatus.START_SOON]: 'Join',
  [SessionModalStatus.ALREADY_STARTED]: 'Ok',
};

export const sessionStatusSecondaryButtonMap: Record<SessionModalStatus, string | null> = {
  [SessionModalStatus.TIME_OUT]: 'Maybe later',
  [SessionModalStatus.REACH_LIMIT]: 'Contact support',
  [SessionModalStatus.SCHEDULED]: null,
  [SessionModalStatus.START_SOON]: 'Reschedule a session',
  [SessionModalStatus.ALREADY_STARTED]: null,
};

export const sessionStatusImageMap: Record<SessionModalStatus, JSX.Element> = {
  [SessionModalStatus.TIME_OUT]: <TimeoutIcon />,
  [SessionModalStatus.REACH_LIMIT]: <CalendarIcon />,
  [SessionModalStatus.SCHEDULED]: <CalendarIcon />,
  [SessionModalStatus.START_SOON]: <TimeoutIcon />,
  [SessionModalStatus.ALREADY_STARTED]: <NotificationIcon />,
};
