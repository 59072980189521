import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const LayoutEmpty = (): JSX.Element => {
  return (
    <Box component="main">
      <Outlet />
    </Box>
  );
};
