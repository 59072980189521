import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { BookingFormField, ReminderValue, RoutePaths, SessionActionModalType } from 'common-types';
import { useGetUserData } from 'hooks/use-get-user-data';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import { memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useAnalytics } from 'services/analytics';
import { invertedButtonColor } from 'styles';

import { DateTime } from '../date-time';
import { ReminderDropdown } from '../reminder-dropdown';
import { telInputStyles } from './styles';
import { IBookingForm } from './utils/types';

export const BookingForm = memo<IBookingForm>(({ isPaidOptionsActive, isMeetLoading }) => {
  const { trackEvent } = useAnalytics();
  const location = useLocation();

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const { userCountry } = useGetUserData();
  const {
    control,
    getValues,
    formState: { errors, dirtyFields, isValid, isSubmitting },
  } = useFormContext();

  const isPhoneDirty = dirtyFields[BookingFormField.PHONE];
  const isReminderDirty = dirtyFields[BookingFormField.REMINDER];
  const userEmail = getValues(BookingFormField.EMAIL);
  const reminderValue = getValues(BookingFormField.REMINDER);

  const isShowEmailField = reminderValue === ReminderValue.EMAIL;
  const isShowPhoneField = reminderValue === ReminderValue.SMS || reminderValue === ReminderValue.WHATSAPP;
  const isButtonDisabled = (isShowPhoneField && !isValid && !isPhoneDirty) || isSubmitting;
  const isShowReminderWarningText = isReminderDirty && isPaidOptionsActive && reminderValue !== ReminderValue.NO_REMIND;

  const handleOpenDropdown = (): void => {
    setIsSelectOpen(true);
  };

  const handleCloseDropdown = (): void => {
    setIsSelectOpen(false);
  };

  const handleFocusField = (field: BookingFormField): void => {
    trackEvent('booking_screen_field_tap', {
      field,
      type: 'book',
      placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
    });
  };

  const handleBlurField = (field: BookingFormField): void => {
    trackEvent('booking_screen_field_filled', {
      field,
      status: !!errors[field] ? 'error' : 'success',
      type: 'book',
      placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
    });
  };

  useEffect(() => {
    if (isSelectOpen && isPaidOptionsActive) {
      trackEvent('paid_notification', { user_email: userEmail, placement: 'onboarding', once: true });
    }
  }, [isPaidOptionsActive, isSelectOpen, trackEvent, userEmail]);

  return (
    <>
      <Stack gap={6} mb={8}>
        <Typography variant="body1" fontSize={16} textTransform="uppercase" color="custom.textGrey">
          Date & time
        </Typography>
        <DateTime pathname={location.pathname} type={SessionActionModalType.ADD} />
      </Stack>
      <Stack gap={6} mb={8}>
        <Typography variant="body1" fontSize={16} textTransform="uppercase" color="custom.textGrey">
          Reminder{isShowReminderWarningText && '*'}
        </Typography>
        <Controller
          name={BookingFormField.REMINDER}
          control={control}
          render={({ field }): JSX.Element => (
            <ReminderDropdown
              field={field}
              isOpen={isSelectOpen}
              onOpen={handleOpenDropdown}
              onClose={handleCloseDropdown}
            />
          )}
        />
        {isShowEmailField && (
          <Controller
            name={BookingFormField.EMAIL}
            control={control}
            render={({ field }): JSX.Element => (
              <TextField
                {...field}
                fullWidth
                placeholder="Enter your e-mail"
                onFocus={(): void => {
                  handleFocusField(BookingFormField.EMAIL);
                }}
                onBlur={(): void => {
                  handleBlurField(BookingFormField.EMAIL);
                  field.onBlur();
                }}
                error={!!errors[BookingFormField.EMAIL]}
                helperText={errors[BookingFormField.EMAIL]?.message.toString()}
                InputProps={{
                  endAdornment: getValues(field.name).length > 0 && (
                    <>
                      <Typography variant="body2" component="span" color="custom.textGrey">
                        {!errors[BookingFormField.EMAIL] ? <CheckRoundedIcon color="success" /> : 'Email'}
                      </Typography>
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        {isShowPhoneField && (
          <Controller
            name={BookingFormField.PHONE}
            control={control}
            render={({ field }): JSX.Element => (
              <MuiTelInput
                {...field}
                defaultCountry={(userCountry?.toUpperCase() as MuiTelInputCountry) || 'US'}
                helperText={errors[BookingFormField.PHONE]?.message.toString()}
                error={!!errors[BookingFormField.PHONE]}
                sx={telInputStyles}
                onFocus={(): void => {
                  handleFocusField(BookingFormField.PHONE);
                }}
                onBlur={(): void => {
                  handleBlurField(BookingFormField.PHONE);
                  field.onBlur();
                }}
                InputProps={{
                  endAdornment: isPhoneDirty && (
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        color="custom.textGrey"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {!errors[BookingFormField.PHONE] ? <CheckRoundedIcon color="success" /> : 'Phone number'}
                      </Typography>
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Stack>
      <Stack gap={6}>
        <Button type="submit" fullWidth sx={[invertedButtonColor, { py: 4 }]} disabled={isButtonDisabled}>
          {isMeetLoading && <CircularProgress size={16} sx={{ color: 'common.white', mr: 4 }} />}
          Continue
        </Button>
      </Stack>
    </>
  );
});
