import { useQuery } from '@tanstack/react-query';
import { ISessionLimit, QueryKey } from 'common-types';
import { useGetUserData } from 'hooks/use-get-user-data';
import chicApi from 'services/chic-api';

interface IGetSessionLimit {
  isGetSessionLimitSuccess: boolean;
  isGetSessionLimitLoading: boolean;
  sessionLimitData: ISessionLimit[];
  isHaveWardrobeReview: boolean;
  isAlreadyBuyWardrobeReview: boolean;
  isHaveSeasonalCapsule: boolean;
  isAlreadyBuySeasonalCapsule: boolean;
  isHaveColorPalette: boolean;
  isAlreadyBuyColorPalette: boolean;
}

export const useGetSessionLimit = (): IGetSessionLimit => {
  const { userId } = useGetUserData();

  const { isSuccess, isLoading, data } = useQuery(
    [QueryKey.USER_SESSION_LIMIT],
    () => chicApi.getSessionLimit(userId),
    {
      enabled: !!userId,
    },
  );

  return {
    isGetSessionLimitSuccess: isSuccess,
    isGetSessionLimitLoading: isLoading,
    sessionLimitData: data,
    isHaveWardrobeReview: Boolean(
      data?.find(
        (item) =>
          (item?.sessionType === 'wardrobe_review' || item?.sessionType === 'wardrobe_revamp') && item?.limit > 0,
      ),
    ),
    isAlreadyBuyWardrobeReview: Boolean(
      data?.find((item) => item?.sessionType === 'wardrobe_review' || item?.sessionType === 'wardrobe_revamp'),
    ),
    isHaveSeasonalCapsule: Boolean(data?.find((item) => item?.sessionType === 'seasonal_capsule' && item?.limit > 0)),
    isAlreadyBuySeasonalCapsule: Boolean(data?.find((item) => item?.sessionType === 'seasonal_capsule')),
    isHaveColorPalette: Boolean(data?.find((item) => item?.sessionType === 'color_palette' && item?.limit > 0)),
    isAlreadyBuyColorPalette: Boolean(data?.find((item) => item?.sessionType === 'color_palette')),
  };
};
