import { Box, useMediaQuery } from '@mui/material';
import { BottomNav } from 'components/bottom-nav';
import { DrawerMenu } from 'components/drawer';
import { HeaderLanding } from 'components/header-landing';
import { ProfileDialog } from 'components/profile-dialog';
import theme from 'providers/theme/theme';
import { Outlet } from 'react-router-dom';

export const LayoutLanding = (): JSX.Element => {
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box component="main" display="flex">
      {isMediumAndDown ? (
        <>
          <HeaderLanding />
          <Box
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            zIndex={1000}
            bgcolor="secondary.light"
            borderTop={`1px solid ${theme.palette.custom.lightGrey}`}
          >
            <BottomNav />
          </Box>
        </>
      ) : (
        <DrawerMenu />
      )}
      <Box minHeight="100vh" width="100%" bgcolor="common.white">
        <Outlet />
      </Box>
      <ProfileDialog />
    </Box>
  );
};
