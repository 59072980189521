import { Typography } from '@mui/material';
import { PopperPlacement } from 'common-types';

export const tutorialSteps = [
  {
    step: 0,
    placement: PopperPlacement.BOTTOM,
    Content: (
      <>
        <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white" mb={3}>
          Welcome to your home page!
        </Typography>
        <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white">
          Choose your current mood each day and get inspired for the appropriate outfit.
        </Typography>
      </>
    ),
  },
  // {
  //   step: 1,
  //   placement: PopperPlacement.LEFT,
  //   Content: (
  //     <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white">
  //       Buy additional fashion guides to get more in-depth guidance in a particular area of fashion.
  //     </Typography>
  //   ),
  // },
  {
    step: 1,
    placement: PopperPlacement.RIGHT,
    Content: (
      <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white">
        This section will show your progress in completing the fashion guide and your style plan so you can see how far
        you have come.
      </Typography>
    ),
  },
  {
    step: 2,
    placement: PopperPlacement.TOP,
    Content: (
      <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white">
        Add your fashion notes here and keep all the useful information at your fingertips.
      </Typography>
    ),
  },
  {
    step: 3,
    placement: PopperPlacement.TOP,
    Content: (
      <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white">
        Here you will find the last material you stopped at.
      </Typography>
    ),
  },
  {
    step: 4,
    placement: PopperPlacement.RIGHT,
    Content: (
      <Typography variant="body1" fontSize={16} fontWeight={500} color="common.white">
        Get quick access to Account information and Settings by clicking on your profile icon.
      </Typography>
    ),
  },
];
