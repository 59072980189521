import { LayoutType, RoutePaths } from 'common-types';
import { FC, lazy, LazyExoticComponent } from 'react';

const SignIn = lazy(() => import('pages/sign-in'));
const NotFound = lazy(() => import('pages/not-found'));
const HomePage = lazy(() => import('pages/home'));
const StylePlan = lazy(() => import('pages/style-plan'));
const StylePlanDay = lazy(() => import('pages/style-plan-day'));
const Guides = lazy(() => import('pages/guides'));
const Profile = lazy(() => import('pages/profile'));
const Stylist = lazy(() => import('pages/stylist'));
const RenewSubscriptionLanding = lazy(() => import('pages/renew-subscription-landing'));
const Unsubscribe = lazy(() => import('pages/unsubscribe'));
const Booking = lazy(() => import('pages/booking'));
const SubscriptionInfo = lazy(() => import('pages/subscription-info'));
const Onboarding = lazy(() => import('pages/additional-onboarding'));
const WardrobeReview = lazy(() => import('pages/wardrobe-review'));
const SessionConfirmation = lazy(() => import('pages/session-confirmation'));
const SeasonalCapsule = lazy(() => import('pages/seasonal-capsule'));
const ColorPalette = lazy(() => import('pages/color-palette'));
const Article = lazy(() => import('pages/article'));
const Highlights = lazy(() => import('pages/highlights'));
const StyleArticle = lazy(() => import('pages/style-article'));

export interface IRoute {
  isPrivate?: boolean;
  path: RoutePaths;
  Component: LazyExoticComponent<FC>;
  layout?: LayoutType;
  isOnlyForSubscribed?: boolean;
}

export const mainRoutes: IRoute[] = [
  {
    path: RoutePaths.HOME,
    Component: HomePage,
    layout: LayoutType.MAIN,
    isPrivate: true,
  },
  {
    path: RoutePaths.MY_ACCOUNT,
    Component: SignIn,
    layout: LayoutType.MAIN,
    isPrivate: true,
  },
  {
    path: RoutePaths.STYLE_PLAN,
    Component: StylePlan,
    layout: LayoutType.MAIN,
    isPrivate: true,
    isOnlyForSubscribed: true,
  },
  {
    path: RoutePaths.STYLE_PLAN_DAY,
    Component: StylePlanDay,
    layout: LayoutType.MAIN,
    isPrivate: true,
    isOnlyForSubscribed: true,
  },
  {
    path: RoutePaths.GUIDES,
    Component: Guides,
    layout: LayoutType.MAIN,
    isPrivate: true,
  },
  {
    path: RoutePaths.PROFILE,
    Component: Profile,
    layout: LayoutType.MAIN,
    isPrivate: true,
  },
  {
    path: RoutePaths.STYLIST,
    Component: Stylist,
    layout: LayoutType.MAIN,
    isPrivate: true,
    isOnlyForSubscribed: true,
  },
  {
    path: RoutePaths.BOOKING,
    Component: Booking,
    layout: LayoutType.EMPTY,
    isPrivate: true,
  },
  {
    path: RoutePaths.SUBSCRIPTION_INFO,
    Component: SubscriptionInfo,
    layout: LayoutType.MAIN,
    isPrivate: true,
  },
  {
    path: RoutePaths.ONBOARDING,
    Component: Onboarding,
    layout: LayoutType.EMPTY,
    isPrivate: true,
  },
  {
    path: RoutePaths.WARDROBE_REVIEW,
    Component: WardrobeReview,
    isPrivate: true,
  },
  {
    path: RoutePaths.SESSION_CONFIRMATION,
    Component: SessionConfirmation,
    isPrivate: true,
  },
  {
    path: RoutePaths.SEASONAL_CAPSULE,
    Component: SeasonalCapsule,
    isPrivate: true,
  },
  {
    path: RoutePaths.COLOR_PALETTE,
    Component: ColorPalette,
    isPrivate: true,
  },
];

export const additionalRoutes: IRoute[] = [
  {
    path: RoutePaths.SIGN_IN,
    Component: SignIn,
    layout: LayoutType.EMPTY,
  },
  {
    path: RoutePaths.NOT_FOUND,
    Component: NotFound,
    layout: LayoutType.EMPTY,
  },
  {
    path: RoutePaths.ARTICLE,
    Component: Article,
    layout: LayoutType.EMPTY,
    isPrivate: false,
  },
  {
    path: RoutePaths.HIGHLIGHTS,
    Component: Highlights,
    layout: LayoutType.EMPTY,
    isPrivate: false,
  },
  {
    path: RoutePaths.STYLE_ARTICLE,
    Component: StyleArticle,
    layout: LayoutType.EMPTY,
    isPrivate: false,
  },
  {
    path: RoutePaths.UNSUBSCRIBE,
    Component: Unsubscribe,
    isPrivate: true,
  },
];

export const landingRoutes: IRoute[] = [
  {
    path: RoutePaths.RENEW_SUBSCRIPTION,
    Component: RenewSubscriptionLanding,
    layout: LayoutType.LANDING,
    isPrivate: true,
  },
];
