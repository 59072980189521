export const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1,
  maxWidth: { xs: 'calc(100% - 32px)', lg: 749 },
  py: { xs: 15, lg: 20 },
  px: 4,
  bgcolor: 'common.white',
  borderRadius: 3,
  outline: 'none',
};
