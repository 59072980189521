export const updateArray = <T>(value: T, values: T[], setFunction: (value: T[]) => void): void => {
  const index = values.findIndex((item) => item === value);

  if (index === -1) {
    setFunction([...values, value]);
    return;
  }

  setFunction(values.filter((item) => item !== value));
};
