import CloseIcon from '@mui/icons-material/Close';
import { Box, Fade, Grid, IconButton, Modal } from '@mui/material';
import { ReactComponent as Menu } from 'assets/icons/edit-note.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { RoutePaths } from 'common-types';
import { Notes } from 'components/notes';
import { Recent } from 'components/recent';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { shallow } from 'zustand/shallow';

export const Header = memo(() => {
  const { isModalOpened, setIsModalOpened } = useStore(
    (state) => ({
      isModalOpened: state.isModalOpened,
      setIsModalOpened: state.setIsModalOpened,
    }),
    shallow,
  );

  return (
    <>
      <Box
        component="header"
        position="fixed"
        top={0}
        left={0}
        px={4}
        width="100%"
        py={2.5}
        zIndex={3}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid item container>
              <Box component={Link} to={RoutePaths.HOME}>
                <Logo />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item container>
              {isModalOpened ? (
                <IconButton onClick={(): void => setIsModalOpened(false)} sx={{ p: 0, color: 'common.black' }}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsModalOpened(true)} sx={{ p: 0 }}>
                  <Menu />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Fade in={isModalOpened} timeout={500}>
        <Modal open={isModalOpened} keepMounted onClose={(): void => setIsModalOpened(false)} sx={{ zIndex: 2 }}>
          <Grid
            container
            direction="column"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            py={10}
            bgcolor="common.white"
          >
            <Grid item>
              <Notes />
            </Grid>
            <Grid item mt="auto">
              <Recent />
            </Grid>
          </Grid>
        </Modal>
      </Fade>
    </>
  );
});
