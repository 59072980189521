export enum PaymentType {
  ADDITIONAL = 'ADDITIONAL',
  MAIN = 'MAIN',
}

export enum ESubscriptionDiscount {
  MONTHLY = 'subscription_30days_id',
  FIFTY_PERCENT = 'subscription_50off_id',
  EIGHT_PERCENT = 'subscription_80off_id',
}

export interface TPaymentCharge {
  userId: string;
  amount?: number;
  productId?: string;
  planSnapshot: {
    price: number;
    currency: string;
    product: {
      type: 'guide' | 'subscription' | 'one_time_session';
      name: string;
      deviceType: 'lumi_web';
      sessionType?: string;
      guideType?: string;
    };
  };
  description: string;
}

export interface IBuyUpsell {
  userId: string;
  productId?: string;
  amount?: number;
  initApplePay?: boolean;
  initGooglePay?: boolean;
  initPaypal: boolean;
  planSnapshot: {
    price: number;
    currency: string;
    product: {
      type: 'guide' | 'subscription' | 'one_time_session';
      name: string;
      deviceType: 'lumi_web';
      sessionType?: string;
      guideType?: string;
    };
  };
  type: PaymentType;
  description: string;
}

export type TSwitchSubscriptionData = {
  productId: string;
  userId: string;
};

export type TSubscriptionDiscount = Record<ESubscriptionDiscount, string>;
