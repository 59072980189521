import { ReviewStep } from 'common-types';
import { useLocalStorageStore } from 'store';
import { MINIMUM_ACCEPTABLE_RATING } from 'utils';
import { StateCreator } from 'zustand';

import { IReviewStore } from './types';

export const reviewSlice: StateCreator<IReviewStore, [], [], IReviewStore> = (set, get) => ({
  reviewModal: {
    isOpen: false,
    step: ReviewStep.RATING,
    rating: null,
  },
  updateReviewModal: (data): void => {
    const reviewModal = get().reviewModal;

    if (reviewModal.step === ReviewStep.RATING && data.rating < MINIMUM_ACCEPTABLE_RATING) {
      set((state) => ({
        reviewModal: {
          ...state.reviewModal,
          ...data,
          step: ReviewStep.FEEDBACK,
        },
      }));
      return;
    }

    if (reviewModal.step === ReviewStep.RATING && data.rating >= MINIMUM_ACCEPTABLE_RATING) {
      set((state) => ({
        reviewModal: {
          ...state.reviewModal,
          ...data,
          step: ReviewStep.TRUSTPILOT,
        },
      }));
      return;
    }

    if (reviewModal.step === ReviewStep.FEEDBACK) {
      set((state) => ({
        reviewModal: {
          ...state.reviewModal,
          step: ReviewStep.FEEDBACK_SUCCESS,
        },
      }));
      return;
    }

    if (reviewModal.step === ReviewStep.FEEDBACK_SUCCESS || reviewModal.step === ReviewStep.TRUSTPILOT) {
      get().closeReviewModal();
      return;
    }

    set((state) => ({
      reviewModal: {
        ...state.reviewModal,
        ...data,
      },
    }));
  },
  openReviewModal: (): void => set({ reviewModal: { isOpen: true, step: ReviewStep.RATING, rating: null } }),
  closeReviewModal: (): void => {
    const setIsReviewModalShown = useLocalStorageStore.getState().setIsReviewModalShown;

    setIsReviewModalShown(true);
    set({ reviewModal: { isOpen: false, step: ReviewStep.RATING, rating: null } });
  },
});
