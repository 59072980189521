export function getEnv(): string {
  let env: string;

  if (!env) {
    env = process.env.REACT_APP_ENV;
  }

  return env;
}

export function isProduction(): boolean {
  return getEnv() === 'production';
}

export function isDevelopment(): boolean {
  return getEnv() === 'development';
}

export function isLocal(): boolean {
  return getEnv() === 'local';
}
