import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal } from '@mui/material';
import { EditSession } from 'components/booking/edit-session';
import { fromUnixTime } from 'date-fns';
import { useGetLastUserSession } from 'hooks/use-get-last-user-session';
import { memo } from 'react';

interface EditSessionModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const EditSessionModal = memo<EditSessionModalProps>(({ isOpen, handleClose }) => {
  const { lastUserSessionTimestamp, lastUserSessionReminder, lastUserSessionId } = useGetLastUserSession();

  return (
    <Modal open={isOpen} onClose={handleClose} keepMounted>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: { xs: 'calc(100% - 32px)', lg: 1 },
          maxWidth: 749,
          bgcolor: 'common.white',
          transform: 'translate(-50%, -50%)',
          maxHeight: { xs: 1, lg: 'calc(100% - 32px)' },
          overflowY: 'auto',
          borderRadius: { xs: 2, lg: 4 },
          outline: 'none',
          px: 4,
        }}
      >
        <Box position="absolute" top={{ xs: 4, lg: 8 }} right={{ xs: 4, lg: 16 }} p={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <EditSession
          isOpen={isOpen}
          handleClose={handleClose}
          meetId={lastUserSessionId}
          datetime={fromUnixTime(lastUserSessionTimestamp)}
          reminder={lastUserSessionReminder}
        />
      </Box>
    </Modal>
  );
});
